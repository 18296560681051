
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    errors: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
    }   
  },
})
