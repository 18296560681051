
  import { defineComponent, ref } from 'vue';
  import { useRoute } from 'vue-router'
  import { ordersStore } from '../../../store/orders/ordersStore';
  import { Preloader } from '../../../components/Preloader';
  import { Error } from '../../../components/Error';

  export default defineComponent({
    components: { Preloader, Error },
    props: {
      step: { 
        type: Number,
        required: true
      },
    },
    setup(props, { emit }) {
      const route = useRoute();
      const errors = ref([]);
      const loading = ref(false);
      const password = ref('');
      const email = ref(route.query.email !== null ? route.query.email : '');
      const token = ref(route.query.token !== null ? route.query.token : '');
      function handleBack() {
        emit('step', props.step - 1);
      }
      function handleNext() {
        errors.value = [];
        loading.value = true; 
        ordersStore.orderSetPassword({ email: email.value.toString(), password: password.value, token: token.value.toString() }).catch((e) => {
          const err = e.response.data.errors; 
          Object.keys(err).forEach((key: string) => {
            for(let i=0;i<err[key].length;i++) {
              (errors.value as string[]).push(err[key][i]);
            }
          })
        }).finally(() => {
          loading.value = false;
        })
      }
      return {
        errors,
        loading,
        handleNext,
        handleBack,
        password,
        email
      };
    },
  })
  