
import { defineComponent, ref, computed } from 'vue';
import { Preloader } from '../../components/Preloader';
import { useRouter } from 'vue-router';
import { usersStore } from '../../store/users/usersStore';
import VPagination from '@hennge/vue3-pagination';
import Manage from '@/components/Admin/User/Manage.vue';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';

export default defineComponent({
  components: {
    Preloader,
    VPagination,
    Manage,
  },
  setup() {
    const router = useRouter();
    const level = ref(1);
    const isShow = ref(false);
    const loaded = ref(true);
    const userData = ref();
    const page = ref(1);
    usersStore.getCurrentUser().then(() => {
      level.value = usersStore.state.user?.access_level
        ? usersStore.state.user.access_level
        : 1;
      if (level.value < 90) {
        router.push('/');
      }
      usersStore.get().finally(() => (loaded.value = false));
    });
    const users = computed(() => {
      return usersStore.state.users;
    });
    const meta = computed(() => {
      return usersStore.state.meta;
    });
    const updateHandler = (page: number) => {
      usersStore.get(page);
    };
    const manage = (user: { [key: string]: any }) => {
      isShow.value = true;
      userData.value = null;
      userData.value = user;
    };
    return {
      isShow,
      userData,
      updateHandler,
      manage,
      page,
      level,
      users,
      meta,
      loaded,
    };
  },
});
