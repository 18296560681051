import { httpClient } from '@/http';

export enum URI {
  promocodes = '/promo-codes',
}

export interface ICreateRequest {
  alias: string|null;
  discount: number|null;
  limit_of_activations: number|null;
  active_from: string|null;
  active_to: string|null;
  id?:  number|null;
}
export interface IUpdateRequest {
  alias?: string|null;
  discount?: number|null;
  limit_of_activations?: number|null;
  active_from?: string|null;
  active_to?: string|null;
  id?: number|null;
}

class PromocodesService {
  public get() {
    return httpClient.get(URI.promocodes);
  }
  public create(create_data: ICreateRequest) {
    return httpClient.post(URI.promocodes, create_data);
  }
  public update(update_data: IUpdateRequest) {
    const id = update_data.id
    return httpClient.patch(`${URI.promocodes}/${id}`, update_data);
  }
  public delete(id: number|null|undefined) {
    return httpClient.delete(`${URI.promocodes}/${id}`);
  }
}

export const promocodesService = new PromocodesService();
