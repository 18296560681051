import { httpClient } from '@/http';
import { IRegisterResponse } from './interfaces';

export enum URI {
  register = '/register',
  login = '/login',
}

class AuthService {
  public passwordSendEmail(email: string) {
    return httpClient.post('/password/email', { email });
  }
  public register(name: string, email: string, password: string) {
    return httpClient.post<IRegisterResponse>(URI.register, {
      name,
      email,
      password,
    });
  }
  public login(email: string, password: string) {
    return httpClient.post<IRegisterResponse>(URI.login, { email, password });
  }
}

export const authService = new AuthService();
