
import { defineComponent, computed, ref } from 'vue';
import { tokenStore } from '../../../store/auth/tokenStore';
import { ordersStore } from '../../../store/orders/ordersStore';
import { Preloader } from '../../../components/Preloader';
import { Error } from '../../../components/Error';

export default defineComponent({
  components: { Preloader, Error },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const response = ref('');
    const errors = ref([]);
    const loading = ref(false);
    const isAuth = computed(() => tokenStore.getters.isAuth.value);
    const name = ref('');
    const email = ref('');
    function handleBack() {
      emit('step', props.step - 1);
    }
    function handleNext() {
      errors.value = [];
      loading.value = true;
      const tokenOrId = ordersStore.state.token || '';
      const information = {
        name: name.value,
        email: email.value,
      }
      ordersStore
        .orderUser(tokenOrId, information)
        .then((r) => {
          ordersStore.state.payment_ct = r.payment_ct;
          sessionStorage.setItem('token', r.token)
          emit('step', props.step + 1);
        })
        .catch((e) => {
          const err = e.response.data.errors;
          if (err) {
            Object.keys(err).forEach((key: string) => {
              for (let i = 0; i < err[key].length; i++) {
                (errors.value as string[]).push(err[key][i]);
              }
            });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    }
    return {
      errors,
      isAuth,
      loading,
      handleNext,
      handleBack,
      name,
      response,
      email,
    };
  },
});
