import { reactive } from 'vue';
import { v4 } from 'uuid';

export interface IAlertMessage {
	key: string;
	message: string;
}

const state = reactive({
	messages: new Map() as Map<string, IAlertMessage>,
});

const actions = {
	add(message: string) {
		const alert = {
			key: v4(),
			message,
		};
		state.messages.set(alert.key, alert);
		autoDelete(alert.key, 3000);
	},
	delete(key: string) {
		state.messages.delete(key);
	},
};

function autoDelete(key: string, ms: number) {
	const timeout = setTimeout(del, ms);

	function del() {
		actions.delete(key);
		clearTimeout(timeout);
	}
}

export const alertStore = {
	state,
	...actions,
};
