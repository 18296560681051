
import { defineComponent, ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { tokenStore } from '../../store/auth/tokenStore';
import { ordersStore } from '../../store/orders/ordersStore';
import { Auth } from '@/components/Orders/Auth';
import { Promocodes } from '@/components/Orders/Promocodes';
import { SavePassword } from '@/components/Orders/SavePassword';
import { Orders } from '@/components/Orders/Orders';
import { Addres } from '@/components/Orders/Addres';
import { Pay } from '@/components/Orders/Pay';
import { CheckPay } from '@/components/Orders/CheckPay';
import { Model } from '@/components/Orders/Model';
import { Color } from '@/components/Orders/Color';
import { Type } from '@/components/Orders/Type';
import { Count } from '@/components/Orders/Count';
import { Info } from '@/components/Orders/Info';

export default defineComponent({
  components: {
    Addres,
    Promocodes,
    Auth,
    Orders,
    SavePassword,
    Pay,
    CheckPay,
    Model,
    Color,
    Type,
    Count,
    Info
  },
  setup() {
    const route = useRoute();
    const isAuth = computed(() => tokenStore.getters.isAuth.value);

    if (route.query.payment_verification) {
      isAuth.value ? ordersStore.state.step = 7 : ordersStore.state.step = 9;
    }

    if (route.query.payment === 'successful') {
      if (window.VK) {
        window.VK.Goal('purchase');
      }
      isAuth.value ? ordersStore.state.step = 8 : ordersStore.state.step = 10;
    }
    else if (route.query.payment === 'error') {
      if(typeof route.query.token === 'string') {
        ordersStore.state.token = route.query.token
      }
      if(typeof route.query.payment_ct === 'string') {
        ordersStore.state.payment_ct = route.query.payment_ct 
      }
      ordersStore.state.step = 6
    }
    const step = ref(ordersStore.state.step);
    // const step = ref(5);
    ordersStore.state.step = 1
    const components = isAuth.value
      ? ref(['Model', 'Color', 'Type', 'Count', 'Info', 'Pay', 'CheckPay', 'Addres'])
      : ref(['Model', 'Color', 'Type', 'Count', 'Info', 'Promocodes', 'Auth', 'Pay', 'CheckPay', 'Addres']);
    const checkedColor = ref(1);
    const checkedVariation = ref(1);
    const count = ref(1);
    const email = ref('');
    const name = ref('');
    const checkColor = computed(() => checkedColor.value);
    function color(setColor: number) {
      switch (setColor) {
        case 1:
          return '#101010';
        case 2:
          return '#ffffff';
        case 3:
          return '#101010';
        case 4:
          return '#ffffff';
      }
    }
    watch(count, () => {
      if (count.value === 0) count.value = 1;
      count.value = parseInt(
        count.value.toString() !== ''
          ? count.value.toString().replace(/[^\d;]/g, '')
          : '1'
      );
    });
    function calc(num: number) {
      count.value += num;
    }
    return {
      components,
      isAuth,
      email,
      name,
      checkedVariation,
      step,
      ordersStore,
      checkColor,
      checkedColor,
      count,
      calc,
      color,
      tokenStore
    };
  },
});
