
  import { defineComponent, ref, watchEffect } from 'vue';
  import { promocodesStore } from "../../../store/promocodes/promocodesStore";

  export default defineComponent({
    props: {
      promocode: {
        type: Object
      },
      isChange: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const alias = ref(props.isChange ? props.promocode?.alias : null)
      const discount = ref(props.isChange ? props.promocode?.discount : null)
      const limit_of_activations = ref(props.isChange ? props.promocode?.limit_of_activations : null)
      const active_from = ref(props.isChange ? props.promocode?.active_from : null)
      const active_to = ref(props.isChange ? props.promocode?.active_to : null)
      const id = ref(props.isChange ? props.promocode?.id : null)

      watchEffect(() => {
        promocodesStore.state.data.alias = alias.value
        promocodesStore.state.data.discount = discount.value
        promocodesStore.state.data.limit_of_activations = limit_of_activations.value
        promocodesStore.state.data.active_from = active_from.value
        promocodesStore.state.data.active_to = active_to.value
        promocodesStore.state.data.id = id.value
      })
      return {
        alias,
        discount,
        limit_of_activations,
        active_from,
        active_to,
      }
  }})
  