
import { defineComponent, ref } from 'vue';
import { ordersStore } from '../../store/orders/ordersStore'

export default defineComponent({
  setup() {
    const data = ref([])
    ordersStore.orders().then((r) => {
      data.value = r.data.data
    })
    function convertStatus(status: string) {
     if(status === 'draft') { return 'черновик' }
     else if(status === 'pending') { return 'в ожидании' }
     else if(status === 'waiting_for_payment') { return 'ожидается платеж' }
     else if(status === 'waiting_for_information') { return 'ожидание информации' }
     else if(status === 'cancelled') { return 'отменено' }
     else if(status === 'processing') { return 'обработка' }
     else if(status === 'sended') { return 'отправлено' }
     else if(status === 'paid') { return 'оплачено' }
     else if(status === 'created') { return 'создан' }
     return status
    }
    function convertAction(action: any) {
      if(action.paid !== 1) {
        return `<a href='/order?payment=error&token=${action.token}&payment_ct=${action.bill.yookassa_payment_ct}'>Оплатить</a>`
      } 
      else if(action.delivery_info_filled !== 1) {
        return `<a href='/order?payment=successful&payment_verification=${action.token}'>Заполнить информацию</a>`
      } 
      else {
        return ''
      }
    }
    function convertDesign(design: string) {
      // desing_1
      // simple_3_u_3
      // simple_3_logo_3
      // classic_white_1
      // classic_custom_30
      const parsestr: string = design.replace(/[^\d]/g, '')
      const color = parsestr[0]
      if(design.indexOf('desing')+1){
        return `<div class="design-own">Свой дизайн</div>`
      }
      else if(design.indexOf('u')+1){
        return `<div style="${color === '3' || color === '2' ? 'color: #000;' : ''}" class="color-${color} design-u">U</div>`
      }
      else if(design.indexOf('logo')+1){
        return `<div style="${color === '3' || color === '2' ? 'color: #000;' : ''}" class="color-${color} design-logo"><span class="title">U</span><span class="description">uTag.pro</span></div>` 
      }
      else if(design.indexOf('classic')+1){
        if (design.indexOf('custom')+1) {
          return `<div class="design-classic__custom">Свой дизайн</div>` 
        } else {
          return `<div style="${color === '3' || color === '2' ? 'color: #000;' : ''}" class="color-${color} design-classic"></div>` 
        }
      }
      return design
    }
    return {
      convertAction,
      convertStatus,
      convertDesign,
      data
    };
  },
});
