import { reactive } from 'vue';
import { ordersService } from '@/services/orders/ordersService';
import { IOrders, IGoods, IOrderUser, IOrderSetPassword, IOrdersAdd } from '@/services/orders/interfaces';

export interface IData {
  message: string | null;
  status: string | null;
  total_amount: number | null;
  order_id?: number|null;
  token?: string|null;
  total_price?: number|null;
}

export interface IState {
  step: number;
  utagModel: string;
  utagType: string;
  utagCount: string;
  utagColor: string;
  token: string;
  tokenAuth: string;
  total_amount: number|null;
  data: IData;
  orders: string[],
  payment_ct: string;
}

const state = reactive<IState>({
  step: 1,
  utagModel: 'simple',
  utagType: 'u',
  utagCount: '1',
  utagColor: 'white',
  token: '',
  tokenAuth: '',
  total_amount: null,
  data: {
    message: null,
    status: null, 
    total_amount: null,
    order_id: null,
    token: null,
    total_price: null
  },
  orders: [],
  payment_ct: ''
});

const actions = {
  updateUtagCount(count: string) {
    state.utagCount = count
  },
  updateUtagType(type: string) {
    state.utagType = type
  },
  updateUtagModel(model: string) {
    state.utagModel = model
  },
  updateUtagColor(color: string) {
    state.utagColor = color
  },
  async orders1(info: IOrders) {
   const { data } = await ordersService.orders1(info);
   state.data = data
   state.total_amount = data.total_amount
   sessionStorage.setItem('total_amount', state.total_amount.toString())
   if(data.order_id) {
    state.token = data.order_id.toString()
   } else {
     state.token = data.token || ''
   }
   state.payment_ct = data.payment_ct || ''
   return data;
  },
  async orderUser(tokenOrId: string, information: IOrderUser) {
    const { data } = await ordersService.orderUser(tokenOrId, information);
    return data
  },


  async goods(goods: IGoods[]) {
    const { data } = await ordersService.goodsAdd(goods);
    state.token = data.token;
    return data
  },
  async promocodes(tokenOrId: string, promoCodeAlias: string) {
    const response = await ordersService.promocodes(tokenOrId, promoCodeAlias)
    return response
  },
  async orders() {
    const response = await ordersService.orders();
    // state.orders = data.data[0].goods;
    return response
  },
  async ordersAdd(tokenOrId: string, information: IOrdersAdd) {
    const response = await ordersService.ordersAdd(tokenOrId, information);
    return response
  },
  async orderSetPassword(information: IOrderSetPassword) {
    const { data } = await ordersService.orderSetPassword(information);
    return data
  }
}
export const ordersStore = {
  state,
  ...actions,
};
