import { promocodesService, ICreateRequest } from '../../services/promocodes/promocodesService';

export interface IPromocode {
  active_from: string;
  active_to: string;
  alias: string;
  discount: number;
  id: number;
  limit_of_activations: number;
  number_of_activations: number;
}

export interface IState {
  data: ICreateRequest;
  promocodes: IPromocode[]
}

const state: IState = {
  data: {
    alias: null,
    discount: null,
    limit_of_activations: null,
    active_from: null,
    active_to: null,
    id: null
  },
  promocodes: []
}

const actions = {
  async get() {
   const response = await promocodesService.get()
   state.promocodes = response.data.data;
   return response;
  },
  async create() {
   const response = await promocodesService.create(state.data)
   return response;
  },
  async update() {
   const response = await promocodesService.update(state.data)
   return response;
  },
  async delete() {
   const response = await promocodesService.delete(state.data.id)
   return response;
  },
}

const clear = () => {
  state.data.alias = null
  state.data.discount = null
  state.data.limit_of_activations = null
  state.data.active_from = null
  state.data.active_to = null
}

export const promocodesStore = {
  state,
  ...actions,
  clear
};

