import { tokenStore } from '@/store/auth/tokenStore';
import { authStore } from '@/store/auth/authStore';
import axios from 'axios';


const baseURL =
  process.env.VUE_APP_API_URL ?? 'https://utag-api.dline-rhost.ru/api';

const excludedUrls = ['/auth', '/order']

const checkUrl = (urls: RegExp[], urlCheck: string) => {
  urls.forEach((url) => {
    if (url.test(urlCheck)) {
      return true;
    }
  });
  return false;
}

const httpClient = axios.create({ baseURL });

const errorCode = (code: number) => {
  switch (code) {
    case 401:
      if (!excludedUrls.includes(location.pathname)) {
        authStore.logout()
        authStore.state.error_list = []
        authStore.state.error_list.push("Авторизация неуспешна" as never)
      }
      break;
  }
}

httpClient.interceptors.request.use(
  (config) => {
    if (!excludedUrls.includes(location.pathname) && Boolean(tokenStore.state.token) === false) {
      if (/\/password-reset\/*/.test(location.pathname)) {
        return
      } else {
        document.location.replace('/auth')
      }
    }
    if (config.url === undefined) return config;
    if (!excludedUrls.includes(config.url)) {
      config.headers.Authorization = `Bearer ${tokenStore.state.token}`;
    }
    return config;
  }
);

httpClient.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  errorCode(error.response.status)
  return Promise.reject(error);
});

export { httpClient };
