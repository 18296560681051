
import { defineComponent, computed, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import { tokenStore } from '../../../store/auth/tokenStore';
import { ordersStore } from '../../../store/orders/ordersStore';
import { Preloader } from '../../../components/Preloader';
import { Error } from '../../../components/Error';
import { stockBalanceStore } from '../../../store/stockBalance/stockBalanceStore';

export default defineComponent({
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  components: { Preloader, Error },
  setup(props, { emit }) {
    const route = useRoute();
    const errors = ref([]);
    const loading = ref(false);
    const colors = ordersStore.state.utagModel === 'classic' ? ['white', 'black', 'custom'] : ['red', 'green', 'white', 'black'];
    if (!colors.includes(ordersStore.state.utagColor)) {
      ordersStore.state.utagColor = 'white';
    }

    const color = computed({
      get(): string {
        return ordersStore.state.utagColor;
      },
      set(value: string) {
        ordersStore.updateUtagColor(value);
      }
    });

    function handleNext() {
      if (ordersStore.state.utagModel === "classic") {
        emit('step', props.step + 2);
      } else {
        emit('step', props.step + 1);
      }
    }
    function handleBack() {
      emit('step', props.step - 1);
    }
    return {
      colors,
      ordersStore,
      errors,
      loading,
      color,
      handleNext,
      handleBack
    };
  },
});
