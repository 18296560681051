import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './styles/styles.scss';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import Maska from 'maska';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://e5bf2cace38e4363a7beb63484b4da99@o1066995.ingest.sentry.io/6060258",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "utag-pa.dline-rhost.ru", "my.utag.pro", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

app.config.globalProperties.$filters = {
  accessLevelToText(level: number) {
    switch(level) {
      case 1: return "пользователь";
      case 90: return "администратор";
      case 100: return "разработчик";
      default: return level;
    }
  },
  formatDate(date: string) {
    const temp = date.split(' ');
    const newDate = temp[0].split('-');
    return `${newDate[2]}.${newDate[1]}.${newDate[0]} ${temp[1]}`
  },
}
app.component('vSelect', vSelect);
app.use(Maska);
app.use(router)
app.mount('#app');
