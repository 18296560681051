import { httpClient } from '@/http';
import { IEditButtonResponse } from './interfaces';

export enum URI {
	buttons = '/buttons',
}

class ButtonService {
	public get() {
		return httpClient.get(URI.buttons);
	}
	public create(payload: IEditButtonResponse) {
		return httpClient.post(URI.buttons, payload);
	}
	public update(id: number, payload: IEditButtonResponse) {
		return httpClient.patch(`${URI.buttons}/${id}`, payload);
	}
	public order(card_id: number, button_ids: number[]) {
		return httpClient.patch(`${URI.buttons}/order/${card_id}`, button_ids);
	}
	public delete(id: number) {
		return httpClient.delete(`${URI.buttons}/${id}`);
	}
}

export const buttonService = new ButtonService();
