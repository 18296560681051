
import { defineComponent, computed } from 'vue';
import { useRouter } from "vue-router"
import { cardStore } from '@/store/cards/cardsStore';
import { tagStore } from '@/store/tags/tagStore';

export default defineComponent({
  setup(_, {emit}) {
    const alias = localStorage.getItem("scan-tag")
    const isShow = computed(() => {
      return tagStore.state.isShowTagBindingPopup && !!cardStore.state.isNewCreateCardId && !!alias
    });
    const router = useRouter();

    const close = () => {
      tagStore.state.isShowTagBindingPopup = false;
    }

    const handleTagBinding = () => {
      close()
      router.push('/tags')
    }
    return { handleTagBinding, isShow, close };
  },
});
