import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e514052"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container mt-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col flex-center" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { "data-label": "#" }
const _hoisted_6 = { "data-label": "имя" }
const _hoisted_7 = { "data-label": "e-mail" }
const _hoisted_8 = { "data-label": "уровень доступа" }
const _hoisted_9 = { "data-label": "последнее посещение" }
const _hoisted_10 = { class: "table-users__box" }
const _hoisted_11 = {
  colspan: "5",
  class: "table-users__manage"
}
const _hoisted_12 = {
  key: 0,
  class: "table-users__border--top"
}
const _hoisted_13 = { colspan: "7" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { colspan: "7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Manage = _resolveComponent("Manage")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_preloader = _resolveComponent("preloader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.level >= 90)
          ? (_openBlock(), _createElementBlock("table", {
              key: 0,
              class: _normalizeClass(["table-users", _ctx.isShow ? 'table-users--hide' : ''])
            }, [
              _createElementVNode("thead", null, [
                _withDirectives(_createElementVNode("th", null, "#", 512), [
                  [_vShow, !_ctx.isShow]
                ]),
                _withDirectives(_createElementVNode("th", null, "имя", 512), [
                  [_vShow, !_ctx.isShow]
                ]),
                _withDirectives(_createElementVNode("th", null, "e-mail", 512), [
                  [_vShow, !_ctx.isShow]
                ]),
                _withDirectives(_createElementVNode("th", null, "уровень доступа", 512), [
                  [_vShow, !_ctx.isShow]
                ]),
                _withDirectives(_createElementVNode("th", null, "последнее посещение", 512), [
                  [_vShow, !_ctx.isShow]
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("tr", {
                    onClick: ($event: any) => (_ctx.manage(user)),
                    key: 'user-' + index,
                    class: "table-users__tr"
                  }, [
                    _createElementVNode("td", _hoisted_5, _toDisplayString(user.id), 1),
                    _createElementVNode("td", _hoisted_6, _toDisplayString(user.name), 1),
                    _createElementVNode("td", _hoisted_7, _toDisplayString(user.email), 1),
                    _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.$filters.accessLevelToText(user.access_level)), 1),
                    _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.$filters.formatDate(user.last_visit_date)), 1)
                  ], 8, _hoisted_4)), [
                    [_vShow, !_ctx.isShow]
                  ])
                }), 128)),
                _createElementVNode("tr", _hoisted_10, [
                  _createElementVNode("td", _hoisted_11, [
                    (_ctx.isShow)
                      ? (_openBlock(), _createBlock(_component_Manage, {
                          key: 0,
                          page: _ctx.page,
                          user: _ctx.userData,
                          onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShow = false))
                        }, null, 8, ["page", "user"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (!_ctx.loaded && _ctx.meta.total / _ctx.meta.per_page >= 2)
                  ? _withDirectives((_openBlock(), _createElementBlock("tr", _hoisted_12, [
                      _createElementVNode("td", _hoisted_13, [
                        _createVNode(_component_v_pagination, {
                          class: "table-users__pagination",
                          modelValue: _ctx.page,
                          "onUpdate:modelValue": [
                            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event)),
                            _ctx.updateHandler
                          ],
                          pages: Math.ceil(_ctx.meta.total / _ctx.meta.per_page),
                          "range-size": 1,
                          "active-color": "#fff"
                        }, null, 8, ["modelValue", "pages", "onUpdate:modelValue"])
                      ])
                    ], 512)), [
                      [_vShow, !_ctx.isShow]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.loaded)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_14, [
                      _createElementVNode("td", _hoisted_15, [
                        _createVNode(_component_preloader)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}