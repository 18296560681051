import { cardService } from '@/services/cards';
import type { ICard } from '@/services/cards/interfaces';
import { reactive } from 'vue';

export type Card = ICard & {
  delete: () => void;
};

const form = reactive({
  name: '',
  surname: '',
  alias: '',
});

const state = reactive({
  dispatched: false,
  isNewCreateCardId: null,
  loading: false,
  list: [] as Card[],
  errors: '',
  card: null as any,
  form,
});

const actions = {
  async search(value: string) {
    try {
      state.loading = true;
      const { data } = await cardService.filter('alias', value);
      state.list = data.data.map((card) => ({
        ...card,
        delete: () => this.delete(card.id),
      }));
    } finally {
      state.loading = false;
      state.dispatched = false;
    }
  },
  async get(id?: number) {
    try {
      state.loading = true;
      if (id) {
        const { data } = await cardService.get(id);
        state.card = data.data;
      } else {
        const { data } = await cardService.get();
        state.list = data.data.map((card) => ({
          ...card,
          delete: () => this.delete(card.id),
        }));
      }
    } finally {
      state.loading = false;
      state.dispatched = false;
    }
  },
  async create(user_id?: number) {
    state.errors = ''
    try {
      const { name, surname, alias } = state.form;
      const response = user_id || user_id === 0 ? await cardService.create(name, surname, alias, user_id) : await cardService.create(name, surname, alias);
      state.isNewCreateCardId = response.data.data.id;
      state.dispatched = true;
      this.get();
      this.resetForm()
      return true;
    } catch (error) {
      // const errors = error.response.data.errors
      const errors = error.response.data
      const html = { value: '' }
      if (error && errors) {
        Object.keys(errors).map((key: string) => {
          errors[key].map((error: string) => html.value +='<div>&bull; ' + error + '</div>')
        })
      }
      state.errors = html.value
      return false;
    }
  },
  async update(id: number, data: any) {
    state.errors = ''
    try {
      await cardService.update(id, data);
      state.dispatched = true;
      this.get();
    } catch (error) {
      if (![500].includes(error.response.status)) {
        const errors = error.response.data.errors
        const html = { value: '' }
        Object.keys(errors).map((key: string) => {
          errors[key].map((error: string) => html.value +='<div>&bull; ' + error + '</div>')
        })
        state.errors = html.value
      } else {
        state.errors = '<div>Ошибка сервера</div>'
      }
    }
  },
  async updateForm(id: number) {
    state.errors = ''
    try {
      const { name, surname, alias } = state.form;
      await cardService.update(id, { name, surname, alias });
      state.dispatched = true;
      this.get();
    } catch (error) {
      // const errors = error.response.data.errors
      const errors = error.response.data
      const html = { value: '' }
      Object.keys(errors).map((key: string) => {
        errors[key].map((error: string) => html.value +='<div>&bull; ' + error + '</div>')
      })
      state.errors = html.value
    }
  },
  async delete(id: number) {
    state.errors = ''
    try {
      await cardService.delete(id);
      this.get();
    } catch (error) {
      const errors = error.response.data.errors
      const html = { value: '' }
      Object.keys(errors).map((key: string) => {
        errors[key].map((error: string) => html.value +='<div>&bull; ' + error + '</div>')
      })
      state.errors = html.value
      // console.warn('Ошибка при удалении карточки', error);
    }
  },
  resetForm() {
    state.form = {
      name: '',
      surname: '',
      alias: ''
    }
  },
  initForm(id: number) {
    const card = state.list.find((card) => card.id === id);
    if (card) {
      state.form.name = card.name;
      state.form.surname = card.surname;
      state.form.alias = card.alias;
    }
  },
};

export const cardStore = {
  state,
  ...actions,
};
