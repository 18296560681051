
import { defineComponent, computed, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import { tokenStore } from '../../../store/auth/tokenStore';
import { ordersStore } from '../../../store/orders/ordersStore';
import { Preloader } from '../../../components/Preloader';
import { Error } from '../../../components/Error';
import { stockBalanceStore } from '../../../store/stockBalance/stockBalanceStore';

export default defineComponent({
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  components: { Preloader, Error },
  setup(props, { emit }) {
    const route = useRoute();
    const errors = ref([]);
    const loading = ref(false);

    const type = computed({
      get(): string {
        return ordersStore.state.utagType;
      },
      set(value: string) {
        ordersStore.updateUtagType(value);
      }
    });

    function handleNext() {
      emit('step', props.step + 1);
    }
    function handleBack() {
      emit('step', props.step - 1);
    }
    return {
      ordersStore,
      errors,
      loading,
      type,
      handleNext,
      handleBack,
    };
  },
});
