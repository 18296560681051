import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3287f660"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "editor__box" }
const _hoisted_2 = { class: "editor__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bold = _resolveComponent("Bold")!
  const _component_Italic = _resolveComponent("Italic")!
  const _component_Link = _resolveComponent("Link")!
  const _component_UnLink = _resolveComponent("UnLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
        class: "editor__action editor__action--icon",
        "data-command": "bold"
      }, [
        _createVNode(_component_Bold)
      ]),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
        class: "editor__action editor__action--icon",
        "data-command": "italic"
      }, [
        _createVNode(_component_Italic)
      ]),
      _createElementVNode("div", {
        href: "#",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
        class: "editor__action editor__action--icon",
        "data-command": "createlink"
      }, [
        _createVNode(_component_Link)
      ]),
      _createElementVNode("div", {
        href: "#",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
        class: "editor__action editor__action--icon",
        "data-command": "unlink"
      }, [
        _createVNode(_component_UnLink)
      ])
    ]),
    _createElementVNode("div", {
      id: "editor",
      class: "editor",
      onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
      contenteditable: ""
    }, null, 32)
  ]))
}