
  import { defineComponent, ref, computed } from 'vue';
  import { Error } from "../../../components/Error";
  import Manage from "./Manage.vue";
  import { promocodesStore, IPromocode } from "../../../store/promocodes/promocodesStore";

  export default defineComponent({
    components: {
      Error,
      Manage
    },
    setup() {
      const errors = ref([])
      const promocodes = ref<IPromocode[]>([])
      // обновление списка промокодов
      const update = () => {
        promocodesStore.get().then(() => {
          if (promocodesStore.state?.promocodes?.length) {
            return promocodes.value = promocodesStore.state.promocodes
          }
          return promocodes.value = []
        })
      }
      update()
      // просмотр промокодов
      const isView = ref(true);
      // создание промокода
      const isCreate = ref(false);
      // редактирование промокода
      const isChange = ref(false);
      const promocode = ref<IPromocode>()
      // текст кнопки
      const btnText = ref("Добавить");

      const open = (component: string) => {
        isView.value = false
        isCreate.value = false
        isChange.value = false
        if (component === "view") { 
          isView.value = true;
          btnText.value = "Добавить";
        }
        if (component === "create") { 
          isCreate.value = true;
          btnText.value = "Создать";
        }
        if (component === "change") { 
          isChange.value = true;
          btnText.value = "Изменить";
        }
      }

      // возвращение на начальный компонент
      const cansel = () => {
        errors.value = [];
        promocodesStore.clear()
        open("view");
        update();
      }
      const convert_date = (bat_date: string) => {
        const temp = bat_date.split(" ");
        const date = temp[0].split("-");
        return `${date[2]}.${date[1]}.${date[0]} ${temp[1]}`
      }
      const change = (data: IPromocode) => {
        const newData = {
          active_from: convert_date(data.active_from),
          active_to: convert_date(data.active_to),
          alias: data.alias,
          discount: data.discount,
          id: data.id,
          limit_of_activations: data.limit_of_activations,
          number_of_activations: data.number_of_activations,
        }
        promocode.value = newData
        open("change")
      }
      const delete_promocode = () => {
        promocodesStore.delete().then(() => cansel())
      }
      const method = () => {
        errors.value = []
        if (isView.value) {
          return open("create");
        }
        if (isCreate.value) {
          promocodesStore.create().then(() => cansel()).catch(e => {
            const err = e.response.data.errors
            Object.keys(err).map(key => {
              err[key].map((error: never) => {
                errors.value.push(error)
              })
            })
          })
        }
        if (isChange.value) {
          promocodesStore.update().then(() => cansel()).catch(e => {
            const err = e.response.data.errors
            Object.keys(err).map(key => {
              err[key].map((error: never) => {
                errors.value.push(error)
              })
            })
          })
        }
      }
      return {
        isChange,
        promocode,
        promocodes,
        errors,
        isView,
        btnText,
        isCreate,
        convert_date,
        delete_promocode,
        change,
        method,
        cansel
      };
    },
  })
  