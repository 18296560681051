
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Promocodes } from "../../components/Admin/Promocodes"
import { usersStore } from '../../store/users/usersStore';

export default defineComponent({
  components: {
    Promocodes
  },
  setup() {
    const router = useRouter()
    const level = ref(1)
    usersStore.getCurrentUser().then(() => {
      level.value = usersStore.state.user?.access_level ? usersStore.state.user.access_level : 1
      if (level.value < 90) {
        router.push("/")
      }
    })
    return {
      level
    };
  },
});
