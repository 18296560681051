import { httpClient } from '@/http';

export enum URI {
  tag = '/tags',
}

class TagService {
  public get(page: number, id?: number, filter?: string, value?: string) {
    if (id) {
      return httpClient.get(`${URI.tag}/${id}?page=${page}`);
    } else {
      const filterUrl = { value: '' };
      if (filter && value) {
        filterUrl.value = `&filter[${filter}]=${value}`
      }
      return httpClient.get(`${URI.tag}?page=${page}` + filterUrl.value);
    }
  }
  public connect(alias: string, activation_pin: string) {
    return httpClient.post(`${URI.tag}/connect`, { alias, activation_pin });
  }
  public update(tagId: number, data: any) {
    return httpClient.patch(`${URI.tag}/${tagId}`, data);
  }
  public byUser(id: number | null) {
    return httpClient.get(`${URI.tag}/byUser/${id}`);
  }
  public freeTags() {
    return httpClient.get(`${URI.tag}/free`);
  }
}

export const tagService = new TagService();
