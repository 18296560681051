import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2874c3d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-box" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
      return (_openBlock(), _createElementBlock("div", {
        key: message.key,
        class: "alert alert-danger",
        role: "alert"
      }, [
        _createTextVNode(_toDisplayString(message.message) + " ", 1),
        _createElementVNode("button", {
          onClick: ($event: any) => (_ctx.deleteMessage(message.key)),
          type: "button",
          class: "btn-close",
          "data-bs-dismiss": "alert",
          "aria-label": "Close"
        }, null, 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}