import { stockBalanceService } from '../../services/stockBalance/stockBalanceService';

export interface IResponse {
  data: {
    simple_1_u: string;
    simple_2_u: string;
    simple_3_u: string;
    simple_4_u: string;
    simple_1_logo: string;
    simple_2_logo: string;
    simple_3_logo: string;
    simple_4_logo: string;
    classic_white: string;
    classic_black: string;
    classic_custom: string;
  }
}
export interface IResponseQuantity {
  data: {
    simple_1_u: number;
    simple_2_u: number;
    simple_3_u: number;
    simple_4_u: number;
    simple_1_logo: number;
    simple_2_logo: number;
    simple_3_logo: number;
    simple_4_logo: number;
    classic_white: number;
    classic_black: number;
    classic_custom: number;
  }
}

const actions = {
  async stockBalance() {
   const response: IResponse = await stockBalanceService.stockBalance()
   return response.data;
  },
  async stockBalanceQuantity() {
   const response: IResponseQuantity = await stockBalanceService.stockBalanceQuantity()
   return response.data;
  },
  async changeStockBalance(data: any) {
   const response: IResponse = await stockBalanceService.changeStockBalance(data)
   return response.data;
  },
}
export const stockBalanceStore = {
  ...actions,
};
