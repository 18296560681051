import { reactive, computed } from 'vue';

export interface ITokenState {
	token: string | null;
}

const state: ITokenState = reactive({
	token: null,
});

const getters = {
	isAuth: computed(() => state.token !== null),
};

const actions = {
	init() {
		const lsToken = localStorage.getItem('token');

		this.set(lsToken);
	},
	set(token: string | null) {
		if (token === null) return;

		state.token = token;
		localStorage.setItem('token', token);
	},
	reset() {
		state.token = null;
		localStorage.removeItem('token');
	},
};

export const tokenStore = {
	state,
	getters,
	...actions,
};
