
import { defineComponent, toRefs, ref, onMounted } from 'vue';
import Bold from '@/components/Icons/Bold.vue';
import Italic from '@/components/Icons/Italic.vue';
import Link from '@/components/Icons/Link.vue';
import UnLink from '@/components/Icons/UnLink.vue';

export default defineComponent({
  components: { Bold, Italic, Link, UnLink },
  props: {
    modelValue: String,
  },
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    onMounted(() => {
      const editor = document.getElementById('editor') as HTMLElement;
      if (editor) {
        editor.focus();
        if (typeof modelValue.value === 'string') {
          editor.innerHTML = modelValue.value;
        }
      }
    });
    const update = (e: any) => {
      emit('update:modelValue', e.target.innerHTML);
    };
    const link = () => {
      const url = prompt('Введите ссылку: ', 'https://');
      if (url?.length && url !== null) {
        document.execCommand('createlink', false, url.toString());
      }
    };
    const unlink = () => {
      const value = document.getSelection();
      if (value) {
        document.execCommand('unlink', false);
      }
    };
    const action = (e: any) => {
      const command = e.target.dataset.command;
      if (command == 'createlink') {
        link();
      } else if (command == 'unlink') {
        unlink();
      } else {
        document.execCommand(command, false);
      }
    };
    return { update, action };
  },
});
