import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { tokenStore } from '@/store/auth/tokenStore';
import AuthPage from '@/pages/AuthPage';
import ProfilePage from '@/pages/ProfilePage';
import TagsPage from '@/pages/TagsPage';
import OrderPage from '@/pages/OrderPage';
import OrdersPage from '@/pages/OrdersPage';
import ActivatePage from '@/pages/ActivatePage';
import StockPage from '@/pages/StockPage';
import PromocodesPage from '@/pages/PromocodesPage';
import UsersPage from '@/pages/UsersPage';
// import PasswordResetPage from '@/pages/PasswordResetPage';

const ifAuth = (to: any, from: any, next: any) => {
  const isAuth = tokenStore.getters.isAuth.value;
  if (!isAuth) {
    return next('/auth');
  }

  next();
};

const ifNotAuth = (to: any, from: any, next: any) => {
  const isAuth = tokenStore.getters.isAuth.value;
  if (isAuth) {
    return next('/');
  }

  next();
};

const routes: Array<RouteRecordRaw> = [
    {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthPage,
    beforeEnter: ifNotAuth,
  },
  {
    path: '/activate/:alias',
    name: 'Activate',
    component: ActivatePage,
  },
  {
    path: '/stock',
    name: 'Stock',
    component: StockPage,
  },
  {
    path: '/promocodes',
    name: 'Promocodes',
    component: PromocodesPage,
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersPage,
  },
  {
    path: '/tags',
    name: 'Tags',
    component: TagsPage,
    beforeEnter: ifAuth,
  },
  {
    path: '/',
    name: 'Profile',
    component: ProfilePage,
    beforeEnter: ifAuth,
  },
  {
    path: '/order',
    name: 'Order',
    component: OrderPage,
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrdersPage,
  },
  // {
  //   path: '/password-reset/:code',
  //   name: 'PasswordReset',
  //   component: PasswordResetPage,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
