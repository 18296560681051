
import { watch, defineComponent, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { Preloader } from '../../../components/Preloader';
import { Error } from '../../Error';
import { ordersStore } from '../../../store/orders/ordersStore';
import { tokenStore } from '../../../store/auth/tokenStore';
import { IPromocodes } from '../../../services/orders/interfaces';

export default defineComponent({
  components: { Preloader, Error },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const errors = ref<string[]>([])
    const message = ref<string|null>(null)
    const loading = ref(true)
    const promocodes = ref("")
    const price = ref<string|null>(localStorage.getItem('price-utag'))

    function handlePromocodes() {
      errors.value = []
      message.value = null
      const tokenOrId = localStorage.getItem('token-utag')
      if (promocodes.value !== "" && tokenOrId) {
        ordersStore.promocodes(tokenOrId.toString(), promocodes.value).then((response: IPromocodes) => {
          message.value = "Промокод активирован!"
          if (response.data.amount) {
            price.value = response.data.amount.toString() 
            localStorage.setItem('price-utag', price.value)
            localStorage.setItem('coupon', promocodes.value) 
          }
          window.ym(84257527,'reachGoal','application_of_a_promotional_code')
        }).catch(e => {
          localStorage.setItem('coupon', '') 
          errors.value = [ e.response.data.message ]
        })
      }
    }

    function handleBack() {
      emit('step', props.step - 1);
    }

    function handleNext() {
      errors.value = [];
      emit('step', props.step + 1);
    }
    window.ym(84257527,'reachGoal','opening_the_promo_code_page')
    return {
      message,
      price,
      promocodes,
      errors,
      loading,
      handlePromocodes,
      handleNext,
      handleBack,
    };
  },
});
