
import { defineComponent, ref } from 'vue';
import PictureView from '@/components/PictureView'

export default defineComponent({
  props: {
    pictures: {
      type: Array,
      default: () => [],
    },
  },
  components: { PictureView },
  setup() {
    const image = ref('')
    const showImage = (picture: string) => {
      image.value = picture;
    }
    const closePictureView = () => {
      image.value = '';
    }
    return {
      showImage,
      image,
      closePictureView
    };
  },
});
