import { httpClient } from '@/http';

export enum URI {
  bill_status = '/bill-status',
}

export interface IResponse {
  bill_status: string;
  paid: boolean;
}


class PayService {
  public get(tokenOrId: any) {
    return httpClient.get<IResponse>(`/orders/${tokenOrId}${URI.bill_status}`);
  }
}

export const payService = new PayService();

