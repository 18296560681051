
import { defineComponent, ref, watch } from 'vue';
import { cardStore } from '@/store/cards/cardsStore';

export default defineComponent({
  setup() {
    const alias = ref();
    const timerId = ref(0);
    watch(alias, () => {
      clearTimeout(timerId.value);
      timerId.value = setTimeout(() => {
        cardStore.search(alias.value);
      }, 800);
    });
    return { alias };
  },
});
