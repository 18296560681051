
import {
  computed,
  defineComponent,
  ref,
  watch,
  onUpdated,
  onMounted,
} from 'vue';
import { buttonStore } from '@/store/buttons/buttonStore';
import { cardStore } from '@/store/cards/cardsStore';
import { usersStore } from '@/store/users/usersStore';
import { Editor } from '../Editor'
import Multiselect from '@vueform/multiselect'

export default defineComponent({
  props: {
    id: Number,
  },
  emits: ['onCancel'],
  components: { Multiselect, Editor },
  setup(props, {emit}) {
    const edit = ref(false);
    if (cardStore.state.isNewCreateCardId) {
      edit.value = true;
    };
    const slip = ref();
    const test = ref()
    const useDeleteButton = ref(false)
    const errors = ref([])
    const level = usersStore.state.user.access_level ? usersStore.state.user.access_level : 1
    const user_id = ref<number|null>(null)
    const buttonTypes = [
      { value: 'phone', name: 'Телефон', icon: '/social-icons/phone.svg' },
      { value: 'linkedin', name: 'LinkedIn', icon: '/social-icons/linkedin.svg' },
      { value: 'email', name: 'E-Mail', icon: '/social-icons/email.svg' },
      { value: 'vk', name: 'ВКонтакте', icon: '/social-icons/vk.svg' },
      {
        value: 'instagram',
        name: 'Instagram',
        icon: '/social-icons/Instagram.svg',
      },
      { value: 'twitter', name: 'Twitter', icon: '/social-icons/twitter.svg' },
      {
        value: 'facebook',
        name: 'Facebook',
        icon: '/social-icons/facebook.svg',
      },
      {
        value: 'signal',
        name: 'Signal',
        icon: '/social-icons/signal.svg',
      },
      { value: 'url', name: 'Ссылка', icon: '/social-icons/url.svg' },
      {
        value: 'whatsapp',
        name: 'WhatsApp',
        icon: '/social-icons/whatsapp.svg',
      },
      {
        value: 'telegram',
        name: 'Telegram',
        icon: '/social-icons/telegram.svg',
      },
      { value: 'viber', name: 'Viber', icon: '/social-icons/viber.svg' },
      { 
        value: 'youtube',
        name: 'YouTube',
        icon: '/social-icons/youtube.svg'
      },
      { 
        value: 'skype',
        name: 'Skype',
        icon: '/social-icons/skype.svg'
      },
      {
        value: 'zoom',
        name: 'Zoom',
        icon: '/social-icons/zoom.svg'
      },
      {
        value: 'app_gallery',
        name: 'AppGallery',
        icon: '/social-icons/appgallery.svg'
      },
      {
        value: 'google_play',
        name: 'Google Play',
        icon: '/social-icons/googleplay.svg'
      },
      {
        value: 'tiktok',
        name: 'TikTok',
        icon: '/social-icons/tiktok.svg'
      },
      {
        value: 'tinkoff_link',
        name: 'Tinkoff',
        icon: '/social-icons/tinkoff.svg'
      },
      {
        value: 'app_store',
        name: 'AppStore',
        icon: '/social-icons/appstore.svg'
      },
      {
        value: 'discord',
        name: 'Discord',
        icon: '/social-icons/discord.svg'
      },
      { value: 'text',  name: 'Текст',  icon: '/social-icons/editor.svg' },
    ];

    const types = {
      phone: 'Телефон',
      linkedin: 'LinkedIn',
      email: 'E-Mail',
      vk: 'ВКонтакте',
      instagram: 'Instagram',
      twitter: 'Twitter',
      facebook: 'Facebook',
      signal: 'Signal',
      url: 'Ссылка',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
      viber: 'Viber',
      youtube: 'YouTube',
      skype: 'Skype',
      zoom: 'Zoom',
      app_gallery: 'AppGallery',
      google_play: 'Google Play',
      tiktok: 'TikTok',
      tinkoff_link: 'Tinkoff',
      app_store: 'AppStore',
      discord: 'Discord',
      text: 'Текст'
    };
    const placeholders = {
      phone: { name: "Мой телефон", value: "79123456789" },
      linkedin: { name: "Мой LinkedIn", value: "https://www.linkedin.com/in/*" },
      email: { name: "Мой e-mail", value: "ivanov@mail.ru" },
      vk: { name: "Мой ВКонтакте", value: "ivanov" },
      instagram: { name: "Мой Instagram", value: "ivanov" },
      twitter: { name: "Мой Twitter", value: "ivanov" },
      facebook: { name: "Мой Facebook", value: "ivanov" },
      signal: { name: "Мой Signal", value: "79123456789" },
      url: { name: "Моя ссылка", value: "https://ivanov.com" },
      whatsapp: { name: "Мой WhatsApp", value: "79123456789" },
      telegram: { name: "Мой Telegram", value: "ivanov" },
      zoom: { name: "Мой Zoom", value: "https://*.zoom.us/j/" },
      app_gallery: { name: "Приложение в AppGallery", value: "https://appgallery.huawei.com/*" },
      viber: { name: "Мой Viber", value: "79123456789" },
      youtube: { name: "Мой YouTube канал", value: "https://www.youtube.com" },
      skype: { name: "Мой Skype", value: "https://join.skype.com/*" },
      google_play: { name: "Приложение для Android", value: "https://play.google.com/store/apps/details?id=*" },
      tiktok: { name: "Мой TikTok", value: "https://vm.tiktok.com/*" },
      tinkoff_link: { name: "Мой Tinkoff", value: "https://www.tinkoff.ru/rm/*" },
      app_store: { name: "Приложение для iOS", value: "https://apps.apple.com/*" },
      discord: { name: "Мой Discord", value: "https://discord.gg/*" },
      text: { name: 'О себе', value: "Олооло" },
    }
    const isButtonCreate = ref(true)

    watch(buttonStore.state, () => {
      if (buttonStore.state.dispatched) {
        edit.value = false;
        buttonStore.state.editableButton = null;
        buttonStore.state.dispatched = false;
        buttonStore.resetForm();
      }
    });
    const beforereorder = (e: any) => {
      if (/demo-no-reorder/.test(e.target.className)) {
        e.preventDefault();
      }
    };
    const beforeswipe = (e: any) => {
      if (e.target.nodeName == 'INPUT' || /no-swipe/.test(e.target.className)) {
        e.preventDefault();
      }
    };
    const beforewait = (e: any) => {
      if (e.target.className.indexOf('instant') > -1) e.preventDefault();
    };
    const reorder = (e: any) => {
      e.target.parentNode.insertBefore(e.target, e.detail.insertBefore);
      const movedItem = buttonStore.state.viewButtons[e.detail.originalIndex];
      buttonStore.state.viewButtons.splice(e.detail.originalIndex, 1);
      buttonStore.state.viewButtons.splice(e.detail.spliceIndex, 0, movedItem);
      if (props.id) {
        buttonStore.order(props.id)
      }
      return false;
    };
    onMounted(() => {
      slip.value.addEventListener('slip:beforereorder', beforereorder, false);
      slip.value.addEventListener('slip:beforeswipe', beforeswipe, false);
      slip.value.addEventListener('slip:beforewait', beforewait, false);
      slip.value.addEventListener('slip:reorder', reorder, false);
      new window.Slip(slip.value);
    });

    const card = computed(() =>
      cardStore.state.list.find((card) => {
        if (card.id === props.id) {
          user_id.value = level > 90 ? card.user.id : card.id;
          return true;
        }
        return false;
      })
    );
    const buttons = computed(() => {
      buttonStore.state.viewButtons = card.value?.compiled_buttons
        ? card.value.compiled_buttons
        : [];
      return buttonStore.state.viewButtons;
    });

    const btnText = computed(() => {
      if (buttonStore.state.editableButton) {
        return 'Сохранить';
      }
      return 'Добавить';
    });
    const onEdit = (contact: any) => {
      buttonStore.onEdit(contact.id, {
        label: contact.label,
        value: contact.value,
        type: contact.type,
      });
      edit.value = true;
    };
    const dispatch = () => {
      if (!isButtonCreate.value) {
        return false
      }
      if (useDeleteButton.value) {
        return (useDeleteButton.value = false);
      }
      isButtonCreate.value = false
      if (buttonStore.state.editableButton) {
        return level >= 90 && user_id.value
          ? buttonStore.update(props.id!, user_id.value).finally(() => isButtonCreate.value = true)
          : buttonStore.update(props.id!).finally(() => isButtonCreate.value = true);
      }
      level >= 90 && user_id.value
        ? buttonStore.create(props.id!, user_id.value).finally(() => isButtonCreate.value = true)
        : buttonStore.create(props.id!).finally(() => isButtonCreate.value = true);
    };

    const deleteButton = () => {
      useDeleteButton.value = true;
      buttonStore.delete().finally(() => {
        cardStore.get();
        isButtonCreate.value = true
      });
    };
    const back = () => {
      buttonStore.state.errors = '';
      cardStore.state.isNewCreateCardId = null;
      emit('onCancel')
    }
    const onCancel = () => {
      buttonStore.state.errors = '';
      edit.value = false;
      buttonStore.state.editableButton = null;
      buttonStore.state.dispatched = false;
      buttonStore.resetForm();
      isButtonCreate.value = true
    };

    return {
      back,
      slip,
      buttonTypes,
      test,
      deleteButton,
      placeholders,
      errors,
      types,
      dispatch,
      buttons,
      buttonStore,
      edit,
      btnText,
      onCancel,
      onEdit,
    };
  },
});
