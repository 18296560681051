
import { defineComponent, computed, ref } from 'vue';
import { tagStore, ITag } from '@/store/tags/tagStore';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: { Multiselect },
  setup(props, { emit }) {
    const tag = computed(() => {
      return tagStore.state.tag;
    });
    const error_message = ref("");
    const success_message = ref("");
    const alias = ref(tag.value?.alias);
    const serial_number = ref(tag.value?.serial_number);
    const protection_password = ref(tag.value?.protection_password);
    const distribution = ref(tag.value?.distribution);
    const options = [
      "Website",
      "WB",
      "Private Gift"
    ];

    if (typeof props.id === 'number') {
      tagStore.get(props.id).then(() => {
        alias.value = tag.value?.alias;
        serial_number.value = tag.value?.serial_number;
        protection_password.value = tag.value?.protection_password;
        distribution.value = tag.value?.distribution;
      });
    }

    const handleChange = () => {
      error_message.value = '';
      success_message.value = '';
      const data: { [key: string]: any } = {};

      if (alias.value) {
        if (alias.value !== tag.value?.alias) {
          data['alias'] = alias.value
        }
      }
      if (serial_number.value) {
        if (serial_number.value !== tag.value?.serial_number) {
          data['serial_number'] = serial_number.value
        }
      }
      if (protection_password.value) {
        if (protection_password.value !== tag.value?.protection_password) {
          data['protection_password'] = protection_password.value
        }
      }
      if (distribution.value) {
        if (distribution.value !== tag.value?.distribution) {
          data['distribution'] = distribution.value
        }
      }

      if (Object.keys(data).length) {
        tagStore.update(props.id, data).then(() => {
          success_message.value = "Успешно обновлено!"
        }).catch((e) => {
          error_message.value = e.response.data[Object.keys(e.response.data)[0]][0];
        })
      } else {
        error_message.value = 'Вы ничего не изменили';
      }
    }
    const close = () => {
      emit('close');
      tagStore.state.tag = null
    };
    return { options, success_message, error_message, alias, serial_number, protection_password, distribution, tag, close, handleChange };
  },
});
