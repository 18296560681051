
import './profile.scss';
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { authStore } from '@/store/auth/authStore';
import { cardStore } from '@/store/cards/cardsStore';
import { usersStore } from '@/store/users/usersStore';
import { EditCard } from '@/components/EditCard';
import { buttonStore } from '../../store/buttons/buttonStore';
import SuccessPopup from '@/components/SuccessPopup/SuccessPopup.vue';
import EditButton from '@/components/EditButton/EditButton.vue';
import { SearchCard } from '@/components/SearchCard';
import TagBindingPopup from '@/components/TagBindingPopup/TagBindingPopup.vue';

export default defineComponent({
  components: {
    EditCard,
    EditButton,
    SuccessPopup,
    SearchCard,
    TagBindingPopup,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const searchValue = ref();
    const popupMessage = ref('');
    const level = computed(() => {
      if (usersStore.state.user?.access_level) {
        return usersStore.state.user.access_level
      } else {
        return 1
      }
    })

    if (route.query.activate === 'success') {
      setTimeout(
        () => (popupMessage.value = 'Вы успешно активировали uTag'),
        1000
      );
    }
    const focusAlias = route.query.alias;
    const editButton = ref<number | null>(null);
    const edit = ref<number | null>(null);
    const isCreatingCard = ref(false);
    const utagPfUrl = process.env.VUE_APP_PFURL;
    const to = (path: string) => {
      router.push(path);
    };
    const cansel = () => {
      isCreatingCard.value = false;
      buttonStore.state.errors = '';
    };
    const handleEdit = (id: number) => {
      isCreatingCard.value = false;
      cardStore.state.isNewCreateCardId = null;
      buttonStore.state.errors = '';
      cardStore.state.errors = '';
      editButton.value = null;
      edit.value = id;
    };
    const close = () => {
      router.push('/');
      cardStore.state.errors = '';
      buttonStore.state.errors = '';
      popupMessage.value = '';
    };
    const handleEditButton = (id: number) => {
      isCreatingCard.value = false;
      cardStore.state.isNewCreateCardId = null;
      buttonStore.state.errors = '';
      cardStore.state.errors = '';
      edit.value = null;
      editButton.value = id;
    };

    onMounted(() => {
      (document.getElementsByClassName('cardd--focus')[0] as any).focus();
    });
    usersStore.getCurrentUser();
    cardStore.get();
    return {
      level,
      searchValue,
      to,
      popupMessage,
      close,
      focusAlias,
      cansel,
      handleEditButton,
      utagPfUrl,
      handleEdit,
      editButton,
      edit,
      cardStore,
      isCreatingCard,
      logout: authStore.logout,
    };
  },
});
