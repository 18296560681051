import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3102bd4d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "picture__list"
}
const _hoisted_2 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PictureView = _resolveComponent("PictureView")!

  return (_ctx.pictures.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pictures, (picture, index) => {
          return (_openBlock(), _createElementBlock("img", {
            key: `pic-${index}-${picture}`,
            class: "picture",
            src: picture,
            alt: "",
            onClick: ($event: any) => (_ctx.showImage(picture))
          }, null, 8, _hoisted_2))
        }), 128)),
        _createVNode(_component_PictureView, {
          image: _ctx.image,
          onClose: _ctx.closePictureView
        }, null, 8, ["image", "onClose"])
      ]))
    : _createCommentVNode("", true)
}