import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", {
      style: _normalizeStyle(`background-image: url(${_ctx.imageBg});`),
      class: _normalizeClass([_ctx.imageBg ? '' : 'upload-img--not-background', "upload-img__label"]),
      for: "upload-img"
    }, null, 6),
    _createElementVNode("input", {
      class: "upload-img__input",
      id: "upload-img",
      type: "file",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadImage($event))),
      accept: "image/*"
    }, null, 32)
  ], 64))
}