import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74f5d0a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-card__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.alias) = $event)),
      class: "search-card",
      placeholder: "Введите алиас для поиска"
    }, null, 512), [
      [_vModelText, _ctx.alias]
    ])
  ]))
}