import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e71c1e6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.image.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "picture-view",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createElementVNode("img", {
          class: "picture-view__image",
          src: _ctx.image,
          alt: ""
        }, null, 8, _hoisted_1)
      ]))
    : _createCommentVNode("", true)
}