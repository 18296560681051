
import { defineComponent, computed } from 'vue';
import { useRouter } from "vue-router"
import { tagStore } from "@/store/tags/tagStore"

export default defineComponent({
  props: {
    isShow: {
      type: Boolean,
      default: false,
    }
  },
  setup(_, {emit}) {
    const router = useRouter()
    const tagUser = computed(() => tagStore.state.tag_user)

    const convertStatus = (status: string) => {
      switch(status) {
        case "created": return "создан"
        case "deleted": return "удален"
      }
    }
    const toCard = (alias: string) => {
      router.push(`/?alias=${alias}`)
    }

    const close = () => {
      emit("close")
    }
    return { tagUser, toCard,convertStatus, close };
  },
});
