import { reactive } from 'vue';
import { activateService } from '@/services/activate/activateService';

const actions = {
  async activate(activation_pin: string, alias: string) {
   const response = await activateService.activate(activation_pin, alias);
   return response;
  },
}
export const activateStore = {
  ...actions,
};
