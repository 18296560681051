import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-92fe4210"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "success-popup" }
const _hoisted_2 = { class: "success-popup__text" }
const _hoisted_3 = { class: "success-popup__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["self"])),
    class: _normalizeClass(["success-popup__bg", _ctx.isShow ? 'success-popup--show' : 'success-popup--hide'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          class: "success-popup__button success-popup__button--right success-popup__button--danger"
        }, "Закрыть")
      ])
    ])
  ], 2))
}