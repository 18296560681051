
import { defineComponent, computed } from 'vue';
import { useRouter } from "vue-router"

export default defineComponent({
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    }
  },
  setup(_, {emit}) {
    const router = useRouter()

    const close = () => {
      emit("close")
    }
    return { close };
  },
});
