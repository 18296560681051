import { reactive } from 'vue';
import { payService } from '@/services/pay/payService';

export interface IState {
  bill_status: boolean;
}

const state = reactive<IState>({
    bill_status: false,
});

const actions = {
  async get(token_or_id: string) {
   const { data } = await payService.get(token_or_id);
   state.bill_status = data.paid
  },
}
export const payStore = {
  state,
  ...actions,
};
