
import { defineComponent, computed, watch, ref, onUpdated } from 'vue';
import { useRoute } from 'vue-router';
import { tokenStore } from '../../../store/auth/tokenStore';
import { ordersStore } from '../../../store/orders/ordersStore';
import { Preloader } from '../../../components/Preloader';
import { Error } from '../../../components/Error';
import { stockBalanceStore } from '../../../store/stockBalance/stockBalanceStore';
import colorsJson from '../../../data/colors.json';
import pricesJson from '../../../data/prices.json';

export default defineComponent({
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  components: { Preloader, Error },
  setup(props, { emit }) {
    const route = useRoute();
    const errors = ref([]);
    const loading = ref(true);
    const price = ref('');
    const stockBalanceList = ref<{ [key: string]: string }>({});
    const stockBalance = ref<string>('');
    const COLORS: { [key: string]: any } = colorsJson;
    const PRICES: { [key: string]: any } = pricesJson;
    const isNext = ref<boolean>(false);

    const ifNext = () => {
      if (stockBalance.value === "<span style='color: green;'>В наличии достаточно uTag</span>") {
        isNext.value = true
      } else { isNext.value = false }
    }

    const convertColor = (color: string) => {
      if (COLORS[color]) {
        return COLORS[color];
      }
      return color;
    };

    const getDesign = () => {
      if (ordersStore.state.utagModel === 'simple') {
        return `${ordersStore.state.utagModel}_${convertColor(
          ordersStore.state.utagColor
        )}_${ordersStore.state.utagType}`;
      } else {
        return `${ordersStore.state.utagModel}_${ordersStore.state.utagColor}`;
      }
    };

    price.value = PRICES[`${getDesign()}_${ordersStore.state.utagCount}`];
    localStorage.setItem('price-utag', price.value);

    const convertStockBalance = () => {
      const design = getDesign()
      if (stockBalanceList.value[design][parseInt(ordersStore.state.utagCount)]) {
        stockBalance.value = "<span style='color: green;'>В наличии достаточно uTag</span>";
      } else {
        stockBalance.value = "<span style='color: red;'>Нет в наличии</span>";
      }
      ifNext(),
      loading.value = false;
    };

    stockBalanceStore.stockBalance().then((r) => {
      stockBalanceList.value = r;
      convertStockBalance()
    });

    const count = computed({
      get(): string {
        return ordersStore.state.utagCount;
      },
      set(value: string) {
        ordersStore.updateUtagCount(value);
      },
    });

    function handleNext() {
      const isAuth = computed(() => tokenStore.getters.isAuth.value);
      const design = { value: '' };
      if (ordersStore.state.utagModel === 'classic') {
        design.value = `${ordersStore.state.utagModel}_${ordersStore.state.utagColor}_${ordersStore.state.utagCount}`;
      } else {
        design.value = `${ordersStore.state.utagModel}_${convertColor(
          ordersStore.state.utagColor
        )}_${ordersStore.state.utagType}_${ordersStore.state.utagCount}`;
      }
      const data = {
        goods: design.value,
      };
      ordersStore.orders1(data).then((r) => {
        if (!isAuth.value && r.token) {
          localStorage.setItem('token-utag', r.token);
        }
        localStorage.setItem('price-utag', price.value.toString());
        localStorage.setItem('design', design.value);
        localStorage.setItem('quanity', ordersStore.state.utagCount);
        emit('step', props.step + 1);
      });
    }
    function handleBack() {
      emit('step', props.step - 1);
    }
    return {
      ifNext,
      isNext,
      price,
      stockBalance,
      ordersStore,
      errors,
      loading,
      count,
      handleNext,
      handleBack,
    };
  },
});
