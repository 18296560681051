import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ec48277"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")!
  const _component_Preloader = _resolveComponent("Preloader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Error, { errors: _ctx.errors }, null, 8, ["errors"]),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Проверяем оплату"))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Preloader, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.is_not_paid)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleNext())),
          class: "mt20 btn btn-primary"
        }, "Проверить ещё раз"))
      : _createCommentVNode("", true)
  ]))
}