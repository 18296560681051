
  import { defineComponent, ref, computed } from 'vue';
  import { useRoute } from 'vue-router'
  import { Preloader } from '../../../components/Preloader';
  import { Error } from '../../../components/Error';
  import { ordersStore } from '../../../store/orders/ordersStore';

  export default defineComponent({
    components: { Preloader, Error },
    props: {
      step: { 
        type: Number,
        required: true
      },
    },
    setup(props, { emit }) {
      const route = useRoute();
      const errors = ref([]);
      const loading = ref(false);
      const password = ref('');
      const email = ref(route.query.email !== null ? route.query.email : '');
      const orders = computed(() => {
        return ordersStore.state.orders
      })
      function handleBack() {
        emit('step', props.step - 1);
      }
      function handleNext() {
        errors.value = [];
        loading.value = true; 
        emit('step', props.step + 1);
        loading.value = false; 
      }
      function getCount(design: string) {
        const parsestr: string = design.replace(/[^\d]/g, '');
        if(design.indexOf('design') + 1) {
          return parsestr[0]
        }
        return parsestr[1]
      }
      function  convertDesign(design: string) {
        if(design.indexOf('u') + 1) { 
          const parsestr: string = design.replace(/[^\d]/g, '');
          const color = parsestr[0];
          return `<div class="color-${color} design-u">U</div>` 
        }
        else if(design.indexOf('logo') + 1) { 
          const parsestr: string = design.replace(/[^\d]/g, '');
          const color = parsestr[0];
          return `<div class="color-${color} design-logo"><span>U</span><span class="description">uTag.pro</span></div>` 
        }
        else if(design.indexOf('design') + 1) { 
          return `<div class="design-own">Свой дизайн</div>` 
        }
      }
      return {
        convertDesign,
        ordersStore,
        errors,
        loading,
        getCount,
        handleNext,
        orders,
        handleBack,
        password,
        email
      };
    },
  })
  