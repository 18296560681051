
import { computed, defineComponent } from 'vue';
import { alertStore } from '@/store/alerts/alertsStore';

export default defineComponent({
	setup() {
		const messages = computed(() =>
			Array.from(alertStore.state.messages).map(([_, message]) => message)
		);

		return {
			messages,
			deleteMessage: alertStore.delete,
		};
	},
});
