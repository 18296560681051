
import { defineComponent, ref } from 'vue';
import Vue from 'vue';
import { usersStore } from '@/store/users/usersStore';
import { Error } from '@/components/Error';
import { Preloader } from '@/components/Preloader';

export default defineComponent({
  props: {
    user: {
      type: Object,
    },
    page: {
      type: Number,
    }
  },
  components: {
    Preloader,
    Error,
  },
  setup(props, { emit }) {
    const options = [
      { key: 1, value: 'Пользователь' },
      { key: 90, value: 'Администратор' },
      { key: 100, value: 'Разработчик' },
    ];
    const loaded = ref(false);
    const errorsList = ref([]);
    const isPassword = ref(false);
    const isAccessLevel = ref(false);
    const pass = ref();
    const rePass = ref();
    const accessLevel = ref(
      options.filter((option) => {
        if (props.user) {
          return option.key === props.user.access_level;
        }
        return false;
      })[0]
    );
    const changePassword = () => {
      errorsList.value = [];
      if (isPassword.value === false) {
        isAccessLevel.value = false;
        isPassword.value = true;
      } else {
        if (props.user) {
          loaded.value = true;
          usersStore
            .editPassword(props.user.id, pass.value, rePass.value)
            .then(() => {
              isPassword.value = false;
              isAccessLevel.value = false;
              emit('back');
            })
            .catch((e) => {
              const errors = e.response.data.errors;
              if (!errors) {
                errorsList.value.push('Ошибка сервера' as never);
              }
              Object.keys(errors).map((key) => {
                errors[key].map((err: never) => {
                  errorsList.value.push(err);
                });
              });
            })
            .finally(() => loaded.value = false);
        }
      }
    };
    const changeAccessLevel = () => {
      errorsList.value = [];
      if (isAccessLevel.value === false) {
        isPassword.value = false;
        isAccessLevel.value = true;
      } else {
        if (props.user) {
          if (accessLevel.value) {
            loaded.value = true;
            usersStore
              .edit(props.user.id, { access_level: accessLevel.value.key })
              .then(() => {
                usersStore.get(props.page).then(() => {
                  isPassword.value = false;
                  isAccessLevel.value = false;
                  emit('back');
                });
              })
              .catch((e) => {
                const errors = e.response.data.errors;
                Object.keys(errors).map((key) => {
                  errors[key].map((err: never) => {
                    errorsList.value.push(err);
                  });
                });
              })
              .finally(() => loaded.value = false);
          }
        }
      }
    };
    const back = () => {
      errorsList.value = [];
      if (isAccessLevel.value || isPassword.value) {
        isPassword.value = false;
        isAccessLevel.value = false;
      } else {
        isPassword.value = false;
        isAccessLevel.value = false;
        emit('back');
      }
    };
    return {
      loaded,
      errorsList,
      back,
      changeAccessLevel,
      changePassword,
      pass,
      rePass,
      isAccessLevel,
      isPassword,
      options,
      accessLevel,
    };
  },
});
