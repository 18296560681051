import { buttonService } from '@/services/buttons/buttonService';
import { IEditButtonResponse } from '@/services/buttons/interfaces';
import { reactive } from 'vue';
import { cardStore } from '../cards/cardsStore';

const form = reactive({
  value: '',
  label: '',
  type: 'vk',
  user_id: ''
});

export interface IButton {
  id: number;
  label: string;
  link: string;
  link_pc: string;
  type: string;
  value: string;
}

export interface ButtonState {
  editableButton: number | null;
  viewButtons: IButton[];
  dispatched: boolean;
  list: any[];
  errors: string;
  form: typeof form;
}

const state = reactive<ButtonState>({
  editableButton: null,
  dispatched: false,
  viewButtons: [],
  list: [],
  errors: '',
  form,
});

const actions = {
  async get() {
    try {
      const { data } = await buttonService.get();

      // console.log(data);
    } catch (error) {
      // console.log('Ошибка при получении контактов карточки', error);
    }
  },
  async create(card_id: number, user_id?: number) {
    state.errors = ''
    try {
      const { value, label, type } = state.form;
      const data = user_id || user_id === 0 ? {
        value,
        label,
        type,
        card_id,
        user_id
      } as IEditButtonResponse
      :{
        value,
        label,
        type,
        card_id,
      } as IEditButtonResponse

      await buttonService.create(data);
      state.dispatched = true;
      cardStore.get();
    } catch (error) {
			if (error.response.status === 500) {
        return state.errors = "Ошибка сервера"
      }
      const errors = error.response.data;
      const html = { value: '' }
      Object.keys(errors).map((key: string) => {
        errors[key].map((error: string) => html.value +='<div>&bull; ' + error + '</div>')
      })
      state.errors = html.value
    }
  },
  async update(card_id: number, user_id?: number) {
    state.errors = ''
    if (state.editableButton === null) return;
    try {
      const { value, label, type } = state.form;
      const payload = user_id || user_id === 0 ? {
        value,
        label,
        type,
        card_id,
        user_id
      } as IEditButtonResponse
      : {
        value,
        label,
        type,
        card_id,
      } as IEditButtonResponse;

      await buttonService.update(state.editableButton, payload);
      state.dispatched = true;
      cardStore.get();
    } catch (error) {
			if (error.response.status === 500) {
        return state.errors = "Ошибка сервера"
      }
      const errors = error.response.data
      const html = { value: '' }
      Object.keys(errors).map((key: string) => {
        errors[key].map((error: string) => html.value +='<div>&bull; ' + error + '</div>')
      })
      state.errors = html.value
      // console.log('Ошибка при редактировании контакта карточки', error);
    }
  },
  async order(card_id: number) {
    try {
      const button_ids = state.viewButtons.map(button => button.id)
      await buttonService.order(card_id, button_ids);
    } catch (error) {
      console.log('Ошибка при обновлении позиции кнопок', error);
    }
  },
  async delete() {
    state.errors = ''
    if (state.editableButton === null) return;
    try {
      await buttonService.delete(state.editableButton);
      state.dispatched = true;
    } catch (error) {
      const errors = error.response.data
      const html = { value: '' }
      Object.keys(errors).map((key: string) => {
        errors[key].map((error: string) => html.value +='<div>&bull; ' + error + '</div>')
      })
      // console.log('Ошибка при удалении контакта карточки', error.response);
    }
  },
  onEdit(id: number, form: any) {
    state.editableButton = id;
    state.form = form;
  },
  resetForm() {
    state.form.value = '';
    state.form.label = '';
    state.form.type = 'vk';
  },
};

export const buttonStore = {
  state,
  ...actions,
};
