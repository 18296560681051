import { httpClient } from '@/http';
import { IResponseCards } from './interfaces';

export enum URI {
  card = '/cards',
}

class CardService {
  public filter(name: string, value: string) {
    return httpClient.get<IResponseCards>(`${URI.card}?filter[${name}]=${value}`);
  }
  public get(id?: number) {
    if (id) {
      return httpClient.get<IResponseCards>(`${URI.card}/${id}`);
    } else {
      return httpClient.get<IResponseCards>(URI.card);
    }
  }
  public create(
    name: string,
    surname: string,
    alias: string,
    user_id?: number
  ) {
    const data =
      user_id || user_id === 0
        ? { name, surname, alias, user_id }
        : { name, surname, alias };
    return httpClient.post(URI.card, data);
  }
  public update(id: number, data: any) {
    return httpClient.patch(`${URI.card}/${id}`, data);
  }

  public delete(id: number) {
    return httpClient.delete(`${URI.card}/${id}`);
  }
}

export const cardService = new CardService();
