import { httpClient } from '@/http';

class StockBalanceService {
  public stockBalance() {
    return httpClient.get('/stock-balance');
  }
  public stockBalanceQuantity() {
    return httpClient.get('/stock-balance/quantity');
  }
  public changeStockBalance(data: any) {
    return httpClient.patch('/stock-balance', data);
  }
}

export const stockBalanceService = new StockBalanceService();
