import { usersService, IUser } from '../../services/users/usersService';
import { reactive } from 'vue';


export interface IState {
  loading: boolean;
  user: IUser;
  users: IUser[];
  meta: { [key: string]: string}
}
const state = reactive<IState>({
  loading: false,
  user: {
    access_level: null,
    email: null,
    id: null,
    last_visit_date: null,
    name: null
  },
  users: [],
  meta: {}
});

const actions = {
  async getCurrentUser() {
    try {
      state.loading = true;
      const { data } = await usersService.getCurrentUser();
      state.user = data;
    } catch (error) {
      console.warn('Ошибка загрузки тегов', error);
    } finally {
      state.loading = false;
    }
  },
  async get(page?: number) {
    try {
      state.loading = true;
      const { data } = await usersService.get(page);
      state.users = data.data;
      state.meta = data.meta;
    } catch (error) {
      console.warn('Ошибка загрузки тегов', error);
    } finally {
      state.loading = false;
    }
  },
  async edit(id: number, data: {[key: string]: any}) {
    const response = await usersService.edit(id, data);
    return response
  },
  async editPassword(id: number, new_password: string, password_confirmation: string) {
    const response = await usersService.editPassword(id, new_password, password_confirmation);
    return response
  },
};

export const usersStore = {
  state,
  ...actions,
};
