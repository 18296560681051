import { httpClient } from '@/http';
import { IPromocodes, IOrders, IGoods, IOrderUser, IOrderSetPassword, IOrdersAdd } from './interfaces';

export enum URI {
  goods = '/goods',
  orders = '/orders',
}

export interface IOrdersResponse {
  data: {
    message: string;
    status: string;
    total_amount: number;
    order_id?: number;
    token?: string;
    total_price?: number;
    payment_ct?: string;
  }
}

class OrdersService {
  public orders1(data: IOrders) {
    return httpClient.post<IOrders, IOrdersResponse>(`${URI.orders}`, data);
  }
  public orderUser(tokenOrId: string, data: IOrderUser) {
    return httpClient.post(`${URI.orders}/${tokenOrId}/contacts`, data);
  }
  public promocodes(tokenOrId: string, promoCodeAlias: string) {
    return httpClient.post<any, IPromocodes>(`/orders/${tokenOrId}/promoCode/${promoCodeAlias}`);
  }


// TEMP
  public goodsAdd(goods: IGoods[]) {
    return httpClient.post(`${URI.goods}`, { goods: goods });
  }
  public get() {
    return httpClient.get(URI.goods);
  }
  public orders(token?: string) {
    return httpClient.get(`${URI.orders}/${token ? token : ''}`);
  }
  public ordersAdd(tokenOrId: string, data: IOrdersAdd) {
    return httpClient.patch(`${URI.orders}/${tokenOrId}`, data);
  }
  public orderSetPassword(data: IOrderSetPassword ) {
    return httpClient.post(`${URI.orders}/set/password`, data);
  }
}

export const ordersService = new OrdersService ();
