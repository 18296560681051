import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-635b44f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cards" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "cards-box" }
const _hoisted_6 = { class: "cards-box" }
const _hoisted_7 = {
  key: 2,
  class: "info"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "actions" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagBindingPopup = _resolveComponent("TagBindingPopup")!
  const _component_success_popup = _resolveComponent("success-popup")!
  const _component_SearchCard = _resolveComponent("SearchCard")!
  const _component_EditCard = _resolveComponent("EditCard")!
  const _component_EditButton = _resolveComponent("EditButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TagBindingPopup),
    _createVNode(_component_success_popup, {
      isShow: !!_ctx.popupMessage,
      message: _ctx.popupMessage,
      onClose: _ctx.close
    }, null, 8, ["isShow", "message", "onClose"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.level >= 90)
              ? (_openBlock(), _createBlock(_component_SearchCard, { key: 0 }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass(["cardd", 
                  _ctx.cardStore.state.list.length && !_ctx.isCreatingCard
                    ? 'cardd--folded'
                    : ''
                ])
              }, [
                (_ctx.isCreatingCard)
                  ? (_openBlock(), _createBlock(_component_EditCard, {
                      key: 0,
                      isNewCard: true,
                      onOnCancel: _ctx.cansel
                    }, null, 8, ["onOnCancel"]))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      href: "",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => {
                    _ctx.isCreatingCard = true;
                    _ctx.edit = null;
                    _ctx.editButton = null;
                    _ctx.cardStore.state.errors = '';
                    _ctx.buttonStore.state.errors = '';
                    _ctx.popupMessage.value = '';
                  }, ["prevent"]))
                    }, "Новая карточка"))
              ], 2),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardStore.state.list, (card) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: card.id,
                    class: _normalizeClass(["cardd", _ctx.focusAlias === card.alias ? 'cardd--focus' : '']),
                    tabindex: "0"
                  }, [
                    (_ctx.edit === card.id)
                      ? (_openBlock(), _createBlock(_component_EditCard, {
                          key: 0,
                          id: card.id,
                          isNewCard: false,
                          onOnCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.edit = null))
                        }, null, 8, ["id"]))
                      : _createCommentVNode("", true),
                    (
                      _ctx.cardStore.state.isNewCreateCardId === card.id ||
                      _ctx.editButton === card.id
                    )
                      ? (_openBlock(), _createBlock(_component_EditButton, {
                          key: 1,
                          id: card.id,
                          onOnCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editButton = null))
                        }, null, 8, ["id"]))
                      : _createCommentVNode("", true),
                    (
                      _ctx.cardStore.state.isNewCreateCardId !== card.id &&
                      _ctx.edit !== card.id &&
                      _ctx.editButton !== card.id
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createElementVNode("p", null, [
                            _createElementVNode("a", {
                              target: "_blank",
                              href: `${_ctx.utagPfUrl}/${card.alias}`,
                              class: "alias-link"
                            }, "@" + _toDisplayString(card.alias), 9, _hoisted_8)
                          ]),
                          _createElementVNode("span", null, _toDisplayString(card.name) + " " + _toDisplayString(card.surname), 1),
                          _createElementVNode("div", _hoisted_9, [
                            (card.tag_count === 0)
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  class: "btn btn-primary w-100 mb-2",
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.to('/tags')))
                                }, " Привязать к uTag "))
                              : _createCommentVNode("", true),
                            _createElementVNode("button", {
                              class: "btn btn-primary w-100 mb-2",
                              onClick: ($event: any) => (_ctx.handleEdit(card.id))
                            }, " Редактировать ", 8, _hoisted_10),
                            _createElementVNode("button", {
                              class: "btn btn-primary w-100 mb-2",
                              onClick: ($event: any) => (_ctx.handleEditButton(card.id))
                            }, " Контакты ", 8, _hoisted_11),
                            _createElementVNode("button", {
                              class: "mob-fix btn btn-danger w-100",
                              onClick: card.delete
                            }, " Удалить ", 8, _hoisted_12)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 2))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}