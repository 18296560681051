import { reactive } from 'vue';
import { imagesService } from '@/services/images';

export interface IState {
  images: any;
}

const state = reactive<IState>({
  images: null,
});

const actions = {
  async get(id?: number) {
    const { data } = await imagesService.get(id);
    state.images = data;
  },
  async upload(form: any) {
    const response = await imagesService.upload(form);
    state.images = response.data.data;
    return response.data.data;
  },
  async delete(id: number) {
   await imagesService.delete(id);
  },
};
export const imagesStore = {
  state,
  ...actions,
};

