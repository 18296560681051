
  import { defineComponent, ref } from 'vue';
  import { stockBalanceStore } from "../../../store/stockBalance/stockBalanceStore"
  import { Error } from '../../../components/Error';

  export default defineComponent({
    components: {
      Error
    },
    setup() {
      const stock_success       = ref(false)
      const errors              = ref([])

      const simple_1_u          = ref()
      const simple_2_u          = ref()
      const simple_3_u          = ref()
      const simple_4_u          = ref()
      const simple_1_logo       = ref()
      const simple_2_logo       = ref()
      const simple_3_logo       = ref()
      const simple_4_logo       = ref()
      const classic_white       = ref()
      const classic_black       = ref()
      const classic_custom      = ref()

      const old_simple_1_u      = ref()
      const old_simple_2_u      = ref()
      const old_simple_3_u      = ref()
      const old_simple_4_u      = ref()
      const old_simple_1_logo   = ref()
      const old_simple_2_logo   = ref()
      const old_simple_3_logo   = ref()
      const old_simple_4_logo   = ref()
      const old_classic_white   = ref()
      const old_classic_black   = ref()
      const old_classic_custom  = ref()

      stockBalanceStore.stockBalanceQuantity().then((response) => {
        simple_1_u.value         = response.simple_1_u
        simple_2_u.value         = response.simple_2_u
        simple_3_u.value         = response.simple_3_u
        simple_4_u.value         = response.simple_4_u
        simple_1_logo.value      = response.simple_1_logo
        simple_2_logo.value      = response.simple_2_logo
        simple_3_logo.value      = response.simple_3_logo
        simple_4_logo.value      = response.simple_4_logo
        classic_white.value      = response.classic_white
        classic_black.value      = response.classic_black
        classic_custom.value     = response.classic_custom

        old_simple_1_u.value     = response.simple_1_u
        old_simple_2_u.value     = response.simple_2_u
        old_simple_3_u.value     = response.simple_3_u
        old_simple_4_u.value     = response.simple_4_u
        old_simple_1_logo.value  = response.simple_1_logo
        old_simple_2_logo.value  = response.simple_2_logo
        old_simple_3_logo.value  = response.simple_3_logo
        old_simple_4_logo.value  = response.simple_4_logo
        old_classic_white.value  = response.classic_white
        old_classic_black.value  = response.classic_black
        old_classic_custom.value = response.classic_custom
      })

      const create_error = (err: any) => {
        Object.keys(err.response.data.errors).map((key: string) => {
          err.response.data.errors[key].map((error: string) => {
            errors.value.push(error as never)
          })
        })
      }
      const stockSuccess = () => {
        stock_success.value = true
      }
      const update = () => {
        errors.value = []
        stock_success.value = false
        if (old_simple_1_u.value !== simple_1_u.value) {
          stockBalanceStore.changeStockBalance({type: "simple_1_u", quantity: simple_1_u.value}).then(() => { stockSuccess()  }).catch((errors) => { create_error(errors) })
        }
        if (old_simple_2_u.value !== simple_2_u.value) {
          stockBalanceStore.changeStockBalance({type: "simple_2_u", quantity: simple_2_u.value}).then(() => { stockSuccess() }).catch((errors) => { create_error(errors) })
        }
        if (old_simple_3_u.value !== simple_3_u.value) {
          stockBalanceStore.changeStockBalance({type: "simple_3_u", quantity: simple_3_u.value}).then(() => { stockSuccess() }).catch((errors) => { create_error(errors) })
        }
        if (old_simple_4_u.value !== simple_4_u.value) {
          stockBalanceStore.changeStockBalance({type: "simple_4_u", quantity: simple_4_u.value}).then(() => { stockSuccess() }).catch((errors) => { create_error(errors) })
        }
        if (old_simple_1_logo.value !== simple_1_logo.value) {
          stockBalanceStore.changeStockBalance({type: "simple_1_logo", quantity: simple_1_logo.value}).then(() => { stockSuccess() }).catch((errors) => { create_error(errors) })
        }
        if (old_simple_2_logo.value !== simple_2_logo.value) {
          stockBalanceStore.changeStockBalance({type: "simple_2_logo", quantity: simple_2_logo.value}).then(() => { stockSuccess() }).catch((errors) => { create_error(errors) })
        }
        if (old_simple_3_logo.value !== simple_3_logo.value) {
          stockBalanceStore.changeStockBalance({type: "simple_3_logo", quantity: simple_3_logo.value}).then(() => { stockSuccess() }).catch((errors) => { create_error(errors) })
        }
        if (old_simple_4_logo.value !== simple_4_logo.value) {
          stockBalanceStore.changeStockBalance({type: "simple_4_logo", quantity: simple_4_logo.value}).then(() => { stockSuccess() }).catch((errors) => { create_error(errors) })
        }
        if (old_classic_white.value !== classic_white.value) {
          stockBalanceStore.changeStockBalance({type: "classic_white", quantity: classic_white.value}).then(() => { stockSuccess() }).catch((errors) => { create_error(errors) })
        }
        if (old_classic_black.value !== classic_black.value) {
          stockBalanceStore.changeStockBalance({type: "classic_black", quantity: classic_black.value}).then(() => { stockSuccess() }).catch((errors) => { create_error(errors) })
        }
        if (old_classic_custom.value !== classic_custom.value) {
          stockBalanceStore.changeStockBalance({type: "classic_custom", quantity: classic_custom.value}).then(() => { stockSuccess() }).catch((errors) => { create_error(errors) })
        }
      }

      return {
        update,
        stock_success,
        errors,
        simple_1_u,
        simple_2_u,
        simple_3_u,
        simple_4_u,
        simple_1_logo,
        simple_2_logo,
        simple_3_logo,
        simple_4_logo,
        classic_white,
        classic_black,
        classic_custom,
      };
    },
  })
  