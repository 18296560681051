
  import { defineComponent, ref, onMounted } from 'vue';
  import { Error } from '../../../components/Error';
  import { ordersStore } from '../../../store/orders/ordersStore';
  import { useRoute } from 'vue-router';
  
  declare global {
    interface Window {
        YooMoneyCheckoutWidget: any;
    }
  }

  export default defineComponent({
    components: { Error },
    props: {
      step: { 
        type: Number,
        required: true
      },
    },
    setup(props, { emit }) {
      const route = useRoute();
      const APP_URL = process.env.VUE_APP_URL;
      const errors = ref([]);
      const payment_id = ordersStore.state.payment_ct;

      const isNextActive = route.query?.payment !== 'error'
      localStorage.setItem("payment_id", payment_id);
      (async () => {
        const tokenOrId = await ordersStore.state.token;
        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: payment_id, //Токен, который перед проведением оплаты нужно получить от ЮKassa
          return_url: `${APP_URL}/order?payment_verification=${tokenOrId}`, //Ссылка на страницу завершения оплаты, это может быть любая ваша страница
          error_callback: function(error: any) {
            console.log(error);
          },
          customization: {
            colors: {
              background: '#212121',
              text: '#ffffff',
              controlPrimary: '#AA7BE3',
              controlPrimaryContent: '#fff',
              border: '#ffffff'
            }
          }
        });
        checkout.render('payment-form');
      })()
      function handleBack() {
        emit('step', props.step - 1);
      }
      window.ym(84257527,'reachGoal','opening_the_payment_page')
      return {
        isNextActive,
        handleBack,
        errors
      };
    },
  })
  