import { httpClient } from '@/http';

export interface IUser {
  access_level: number|null;
  email: string|null;
  id: number|null;
  last_visit_date: string|null;
  name: string|null;
}

export interface IUsers {
  data: IUser[]
  meta: { [key: string]: string }
}

export enum URI {
  user = '/user',
  users = '/users',
}

class UsersService {
  public getCurrentUser() {
    return httpClient.get<IUser>(URI.user);
  }
  public get(page?: number) {
    const query = page ? `?page=${page}` : ''
    return httpClient.get<IUsers>(`${URI.users}/${query}`);
  }
  public edit(id: number, data: {[key: string]: any}) {
    return httpClient.patch<{[key: string]: any}>(`${URI.users}/${id}`, data);
  }
  public editPassword(id: number, new_password: string, password_confirmation: string) {
    return httpClient.patch<{[key: string]: any}>(`${URI.users}/${id}/password`, { new_password, password_confirmation });
  }
}

export const usersService = new UsersService();
