
import { defineComponent, ref, computed, watch } from 'vue';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import { imagesStore } from '@/store/images/imagesStore';
import { cardStore } from '@/store/cards/cardsStore';
import { Preloader } from '@/components/Preloader';
import UploadImage from '@/components/UploadImage/UploadImage.vue';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.compact.css';

export default defineComponent({
  components: { Cropper, CircleStencil, UploadImage, Preloader },
  props: {
    id: {
      type: Number,
      requered: true,
    },
  },
  setup(props, { emit }) {
    const showPopper = ref(false);
    const options = [
      { key: 'large', value: 'Большая версия' },
      { key: 'small', value: 'Мини-версия' },
      { key: 'small_to_large', value: 'Мини + просмотр' },
    ];

    // получаем данные выбранной карточки
    const helpView = ref(false);
    const isViewCrop = ref(false);
    const displayType = ref(
      cardStore.state.card?.avatar?.display_type || 'large'
    );
    const cropper = ref();
    const image = ref<{ src: any; type: any }>({ src: null, type: null });
    const currentAvatar = ref();
    const loading = ref(false);
    const card = ref(cardStore.state.card);

    // загружаем и устанавливаем изображение для текущей карточки
    if (card.value) {
      if (card.value.avatar) {
        imagesStore.get(card.value.avatar.id).then(() => {
          if (imagesStore.state.images.data.images.large) {
            currentAvatar.value =
              imagesStore.state.images.data.images.large.url;
          } else if (imagesStore.state.images.data.images.small) {
            currentAvatar.value =
              imagesStore.state.images.data.images.small.url;
          } else {
            currentAvatar.value =
              imagesStore.state.images.data.images.original.url;
          }
        });
      }
    }
    // при смене типа аватара отправляем запрос на сервер
    watch(displayType, (value, oldValue) => {
      if (!isViewCrop.value) {
        if (typeof props.id === 'number' && card.value?.avatar) {
          loading.value = true;
          cardStore
            .update(props.id, {
              avatar_image_id: card.value.avatar.id,
              avatar_display_type: value,
            })
            .then(() => {
              cardStore.get().then(() => {
                loading.value = false;
              });
            });
        }
      }
    });

    // Загружаем картинку с диска
    const uploadImage = (img: any) => {
      image.value = img;
      isViewCrop.value = true;
    };

    // Загружаем картинку на сервер
    const uploadServer = () => {
      const { canvas } = cropper.value.getResult();
      if (canvas) {
        loading.value = true;
        const form = new FormData();
        canvas.toBlob((blob: any) => {
          form.append('user_id', '9');
          form.append('image', blob);
          imagesStore.upload(form).then((r) => {
            if (props.id) {
              const avatar_display_type = displayType.value;
              cardStore
                .update(props.id, {
                  avatar_image_id: r.id,
                  avatar_display_type,
                })
                .then(() => {
                  // удаляем старую
                  if (card.value?.avatar?.id) {
                    imagesStore.delete(card.value.avatar.id);
                  }
                  emit('back');
                });
            }
          });
        }, 'image/jpeg');
      }
    };

    const back = () => {
      emit('back');
    };

    // удаляем картинку на сервере и в карточке
    const deleteImage = () => {
      if (props.id) {
        loading.value = true;
        cardStore.update(props.id, { avatar: null }).then(() => {
          if (card.value?.avatar?.id) {
            imagesStore.delete(card.value.avatar.id).finally(() => {
              emit('back');
            });
          } else {
            emit('back');
          }
        });
      }
    };
    return {
      helpView,
      showPopper,
      card,
      options,
      deleteImage,
      loading,
      back,
      displayType,
      currentAvatar,
      uploadServer,
      uploadImage,
      image,
      isViewCrop,
      cropper,
    };
  },
});
