<template>
  <form class="card-contact" @submit.prevent="dispatch">
    <div
      v-if="cardStore.state.errors.length"
      v-html="cardStore.state.errors"
      class="card-contact__error"
    ></div>
    <Avatar :id="id" v-if="isAvatar && !isNewCard" @back="isAvatar = false" />
    <div
      v-if="!isAvatar"
      :style="isNewCard ? 'height: 250px' : 'height: 196px'"
    >
      <div class="mb-2" v-if="level >= 90 && isNewCard">
        <input
          type="text"
          class="form-control"
          placeholder="ID юзера"
          v-model="user_id"
        />
      </div>
      <div class="mb-2">
        <input
          type="text"
          autocomplete="name"
          class="form-control"
          placeholder="Имя"
          v-model="name"
        />
      </div>
      <div class="mb-2">
        <input
          type="text"
          autocomplete="surname"
          class="form-control"
          placeholder="Фамилия"
          v-model="surname"
        />
      </div>
      <div class="mb-2">
        <input
          type="text"
          autocomplete="nickname"
          class="form-control"
          placeholder="Псевдоним"
          v-model="alias"
        />
      </div>
    </div>
    <div>
      <button
        v-if="!isAvatar && !isNewCard"
        @click="showAvatar"
        type="button"
        class="btn btn-primary w-100 mb-2"
      >
        Фотография
      </button>
      <button v-if="!isAvatar" type="submit" class="btn btn-primary w-100 mb-2">
        {{ btnText }}
      </button>
      <button
        v-if="!isAvatar"
        @click="
          $emit('onCancel');
          cardStore.state.errors = '';
        "
        class="mob-fix btn btn-danger w-100"
      >
        Назад
      </button>
    </div>
  </form>
</template>
<script>
import { ref, computed, defineComponent, onUnmounted, watch } from 'vue';
import { cardStore } from '@/store/cards/cardsStore';
import { tagStore } from '@/store/tags/tagStore';
import { usersStore } from '@/store/users/usersStore';
import Avatar from '@/components/Avatar/Avatar';

export default defineComponent({
  props: {
    id: {
      type: Number,
      default: null,
    },
    isNewCard: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onCancel'],
  components: { Avatar },
  setup(props, { emit }) {
    const name = ref();
    const surname = ref();
    const alias = ref();
    const isFreeTag = ref(tagStore.freeTags());
    cardStore.get(props.id).then(() => {
      if (!props.isNewCard) {
        name.value = cardStore.state.card.name;
        surname.value = cardStore.state.card.surname;
        alias.value = cardStore.state.card.alias;
      }
    });
    const isAvatar = ref(false);
    const user_id = ref('');
    const level = usersStore.state.user.access_level
      ? usersStore.state.user.access_level
      : 1;
    const isEdit = !!props.id;
    if (props.isNewCard) {
      cardStore.state.form = {
        name: '',
        surname: '',
        alias: '',
      };
    }
    watch(cardStore.state, () => {
      if (!props.isNewCard && cardStore.state.dispatched) {
        emit('onCancel');
      }
    });
    watch(alias, () => {
      alias.value = alias.value.replace(/[^A-Za-z._]/g, '');
      cardStore.state.form.alias = alias.value;
    });

    if (props.id) {
      cardStore.initForm(props.id);
    }

    onUnmounted(() => {
      cardStore.state.created = false;
    });

    const btnText = computed(() => (isEdit ? 'Сохранить' : 'Добавить'));
    const back = () => {
      if (isAvatar.value) {
        isAvatar.value = false;
      } else {
        emit('onCancel');
      }
      cardStore.state.errors = '';
    };
    const dispatch = () => {
      cardStore.state.form.name = name.value;
      cardStore.state.form.surname = surname.value;
      cardStore.state.form.alias = alias.value;
      if (isEdit) {
        return cardStore.updateForm(props.id);
      }
      if (level >= 90 && props.isNewCard) {
        cardStore.create(parseInt(user_id.value)).then(() => {
          if (cardStore.state.errors === '') {
            emit('onCancel');
          }
        });
      } else {
        cardStore.create().then((status) => {
          if (status) {
            tagStore.state.isShowTagBindingPopup = isFreeTag.value;
          }
          if (cardStore.state.errors === '') {
            emit('onCancel');
          }
        });
      }
    };
    const showAvatar = () => {
      cardStore.state.errors = '';
      isAvatar.value = true;
    };

    return {
      showAvatar,
      name,
      surname,
      alias,
      back,
      isAvatar,
      user_id,
      level,
      btnText,
      dispatch,
      cardStore,
    };
  },
});
</script>
<style>
.card-contact {
  padding: 15px;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.card-contact::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.card-contact__error {
  padding: 10px;
  width: 100%;
  background: #d34e62;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 12px;
}
</style>
