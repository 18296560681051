
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    imageBg: {
      type: String,
    }
  },
  emits: ['update'],
  setup(_, { emit }) {
    const image = ref<{src: any;type: any}>({ src: null, type: null });
    const getMimeType = (file: any, fallback = null) => {
      const byteArray = new Uint8Array(file).subarray(0, 4);
      let header = '';
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case '89504e47':
          return 'image/png';
        case '47494638':
          return 'image/gif';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg';
        default:
          return fallback;
      }
    };
    const loadImage = (event: any) => {
      const { files } = event.target;
      if (files && files[0]) {
        if (image.value.src) {
          URL.revokeObjectURL(image.value.src);
        }
        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();
        reader.onload = (e: any) => {
          image.value = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type),
          };
          emit("update", image.value)
        };
        reader.readAsArrayBuffer(files[0]);
      }
    };
    return { loadImage, image };
  },
});
