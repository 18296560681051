
import {  computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { authStore } from '@/store/auth/authStore';

export default defineComponent({
  setup() {
    const isRegister = ref(false);
    const route = useRoute();
    const error = ref(null);
    const isPasswordReset = ref(false);
    if (route.query.token && route.query.token !== '' && typeof route.query.token === 'string') {
      authStore.autologin(route.query.token, '/')
    }
    if (typeof route.query.registration === 'string' && route.query.registration) {
      isRegister.value = true
      localStorage.setItem("scan-tag", route.query.registration)
      window.ym(84257527,'reachGoal','opening_the_registration_page')
    }
    const isShowRegister = computed(() => {
      if(route.query.alias) {
        return true
      }
      return false
    })
    
    const toggleMethod = () => {
      isRegister.value = !isRegister.value;
    };

    const toggleBtnText = computed(() => {
      if (isRegister.value) {
        return 'Уже есть аккаунт';
      }

      return 'Создать аккаунт';
    });

    const btnText = computed(() => {
      if (isRegister.value) {
        return 'Создать аккаунт';
      }

      return 'Войти';
    });

    const dispatch = () => {
      const alias = sessionStorage.getItem('alias') || route.query.registration
      if (isRegister.value) {
        return authStore.register(`/activate/${alias}`);
      }
      else if (alias && alias !== '') {
        authStore.login(`/activate/${alias}`)
      }
      else {
        authStore.login('/');
      }
    };

    const resetPassword = () => {
      if (isPasswordReset.value) {
        authStore.passwordSendEmail().then(() => {
          authStore.state.error_list = []
          authStore.state.success = ''
          authStore.state.success = "Ссылка для сброса пароля была отправлена на вашу почту."
        })
      } else {
        authStore.state.error_list = []
        authStore.state.success = ''
        isPasswordReset.value = true
      }
    }
    const back = () => {
      authStore.state.error_list = []
      authStore.state.success = ''
      isPasswordReset.value = false
    }

    return {
      back,
      resetPassword,
      isPasswordReset,
      isShowRegister,
      dispatch,
      toggleMethod,
      toggleBtnText,
      btnText,
      isRegister,
      authStore,
    };
  },
});
