import { httpClient } from '@/http';
import { IImage, IResponseImages, IRequestImage } from './interfaces';

export enum URI {
  images = '/images',
}

class ImagesService {
  public get(id?: number) {
    if (id || id === 0) {
      return httpClient.get<IImage>(`${URI.images}/${id}`);
    } else {
      return httpClient.get<IResponseImages>(URI.images);
    }
  }
  public upload(form: any) {
    return httpClient.post<IRequestImage, any>(URI.images, form);
  }
  public update(id: number, user_id: number, image: any) {
    return httpClient.patch(`${URI.images}/${id}`, { user_id, image });
  }
  public delete(id: number) {
    return httpClient.delete(`${URI.images}/${id}`);
  }
}

export const imagesService = new ImagesService();
