
import { defineComponent, computed, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import { tokenStore } from '../../../store/auth/tokenStore';
import { ordersStore } from '../../../store/orders/ordersStore';
import { Preloader } from '../../../components/Preloader';
import { Error } from '../../../components/Error';
import { stockBalanceStore } from '../../../store/stockBalance/stockBalanceStore';

export default defineComponent({
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  components: { Preloader, Error },
  setup(props, { emit }) {
    const route = useRoute();
    const errors = ref([]);
    const loading = ref(false);
    const counts =
      ordersStore.state.utagModel === 'classic'
        ? ordersStore.state.utagColor === 'custom'
          ? ['30', '50', '100']
          : ['1', '3', '5', '10']
        : ['1', '3'];
    if (!counts.includes(ordersStore.state.utagCount)) {
      ordersStore.state.utagCount = counts[0];
    };

    const count = computed({
      get(): string {
        return ordersStore.state.utagCount;
      },
      set(value: string) {
        ordersStore.updateUtagCount(value);
      },
    });

    const convertColor = (color: string) => {
      if (color) {
        switch(color) {
          case "red": return 1;
          case "green": return 2;
          case "white": return 3;
          case "black": return 4;
          case "custom": return 5;
          default: return color
        }
      }
      else {
        return color;
      }
    }

    function handleNext() {
      emit('step', props.step + 1);
    }
    function handleBack() {
      if (ordersStore.state.utagModel === 'classic') {
        emit('step', props.step - 2);
      } else {
        emit('step', props.step - 1);
      }
    }
    return {
      counts,
      ordersStore,
      errors,
      loading,
      count,
      handleNext,
      handleBack,
    };
  },
});
