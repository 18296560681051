
import { defineComponent, ref, computed, watch } from 'vue';
import { tagStore } from '@/store/tags/tagStore';
import UserPopup from '@/components/UserPopup';
import TagPopup from '@/components/TagPopup';
import { cardStore } from '@/store/cards/cardsStore';
import { useRoute } from 'vue-router';
import VPagination from '@hennge/vue3-pagination';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
import { usersStore } from '@/store/users/usersStore';

const connect = 'connect';

export default defineComponent({
  components: {
    UserPopup,
    TagPopup,
    VPagination,
  },
  setup() {
    const level = ref(1);
    usersStore.get().then(() => {
      level.value = usersStore.state.user?.access_level
        ? usersStore.state.user.access_level
        : 1;
    });
    const filter = ref('serial_number');
    const searchTag = ref();
    const page = ref(1);
    const pages = Math.ceil(
      tagStore.state.meta.total / tagStore.state.meta.per_page
    );
    const isShowUser = ref(false);
    const isShowTag = ref(false);
    const tagId = ref();
    const user = ref({});
    const isEdit = ref(false);
    const tags = computed(() => tagStore.state.list)
    const loading = ref(true);

    const isConnecting = ref(false);

    const { query } = useRoute();

    cardStore.get();
    tagStore.get().finally(() => {
      loading.value = false;
    });

    if (query.action === connect && query.alias) {
      isConnecting.value = true;
      tagStore.setForm(query.alias as string);
    }

    const onEdit = () => {
      isEdit.value = true;
    };
    const viewUser = (id: number) => {
      tagStore.byUser(id).then(() => {
        isShowUser.value = true;
      });
    };
    const handleChange = async (event: any, tagId: number) => {
      if (event.target.value === 'none') {
        return;
      }
      await tagStore.update(tagId, { card_id: Number(event.target.value) });
      isEdit.value = false;
    };
    const closePopup = () => {
      isShowUser.value = false;
      isShowTag.value = false;
    };
    const convertStatus = (status: string) => {
      switch (status) {
        case 'created':
          return 'создан';
        default:
          return '';
      }
    };
    const showTag = (id: string) => {
      tagId.value = id;
      isShowTag.value = true;
    };
    const timerId = ref(0);

    watch(page, () => {
      tagStore.state.page = page.value;
      tagStore.get();
    });

    watch(searchTag, () => {
      clearTimeout(timerId.value);
      timerId.value = setTimeout(() => {
        tagStore.search(filter.value, searchTag.value);
      }, 800);
    });

    watch(filter, () => {
      clearTimeout(timerId.value);
      timerId.value = setTimeout(() => {
        tagStore.search(filter.value, searchTag.value);
      }, 800);
    });

    return {
      tags,
      loading,
      pages,
      filter,
      searchTag,
      showTag,
      page,
      convertStatus,
      isShowUser,
      tagId,
      isShowTag,
      viewUser,
      user,
      level,
      handleChange,
      onEdit,
      isEdit,
      cardStore,
      tagStore,
      isConnecting,
      closePopup,
    };
  },
});
