import { reactive } from 'vue';
import { authService } from '@/services/auth';
import { tokenStore } from './tokenStore';
import { alertStore } from '../alerts/alertsStore';
import { buttonStore } from '../buttons/buttonStore';
import router from '@/router';

const state = reactive({
  loading: false,
  name: '',
  email: '',
  password: '',
  error_list: [],
  success: '',
});

const actions = {
  init() {
    tokenStore.init();
  },
  async passwordSendEmail() {
    try {
      await authService.passwordSendEmail(state.email);
    } catch (error: any) {
      state.success = ''
      const message = error.response.data.message
      Object.keys(message).map(key => {
        message[key].map((error: never) => {
          state.error_list.push(error)
        })
      })
    } finally {
      state.loading = false;
    }
  },
  async register(path?: string) {
    try {
      state.error_list = []
      state.loading = true;
      const { name, email, password } = state;
      const { data } = await authService.register(name, email, password);
      tokenStore.set(data.token);
      path ? router.push(path) : router.push('/');
    } catch (error: any) {
      state.success = ''
      const message = error.response.data.message
      Object.keys(message).map(key => {
        message[key].map((error: never) => {
          state.error_list.push(error)
        })
      })
    } finally {
      state.loading = false;
    }
  },
  async autologin(token: string, path?: string) {
    // console.log('TOKEN', token)
    tokenStore.set(token);
    path ? router.push(path) : router.push('/');
  },
  async login(path?: string) {
    try {
      state.loading = true;
      const { email, password } = state;
      const { data } = await authService.login(email, password);
      tokenStore.set(data.token);
      path ? router.push(path) : router.push('/');
    } catch (error: any) {
      const { message, error: err } = error.response.data;

      if (err === 'unauthorized') {
        alertStore.add(message);
      }
      // console.warn('Ошибка при авторизации', error);
    } finally {
      state.loading = false;
    }
  },
  logout() {
    tokenStore.reset();
    buttonStore.resetForm();
    buttonStore.resetForm();
    state.email = '';
    state.name = '';
    state.password = '';
    router.push('/auth');
  },
};

export const authStore = {
  state,
  ...actions,
};
