import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25c1824c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "errors"
}
const _hoisted_2 = {
  key: 1,
  class: "errors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.errors.length > 0 ? '' : 'hide', "errors-wrapper"])
  }, [
    (_ctx.errors.length === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errors[0]), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "error-mesage",
              key: index
            }, "• " + _toDisplayString(error), 1))
          }), 128))
        ]))
  ], 2))
}