import { httpClient } from '@/http';

class ActivateService {
  public activate(activation_pin: string, alias: string) {
    return httpClient.post('/tags/connect', {activation_pin, alias});
  }
  
}

export const activateService = new ActivateService();
