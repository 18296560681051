
import { ref, defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import Alert from '@/components/Alert';
import { authStore } from './store/auth/authStore';
import { tokenStore } from './store/auth/tokenStore';
import { usersStore } from './store/users/usersStore';

export default defineComponent({
  setup() {
    usersStore.getCurrentUser()
    const level = computed(() => {
      if (usersStore.state.user?.access_level) {
        return usersStore.state.user.access_level
      } else {
        return 1
      }
    })
    const router = useRouter()
    const nameRouter = {
      Tags: 'Тэги',
      Profile: 'Карточки',
      Order: 'Оформление заказа',
      Orders: 'Заказы',
      Stock: 'Остатки на складе',
      Promocodes: 'Промокоды',
      Users: 'Пользователи',
    }
    const isShowMobileNav = ref(false)
    authStore.init();
    const isAuth = computed(() => tokenStore.getters.isAuth.value)
    const navMobile = () => {
      isShowMobileNav.value = !isShowMobileNav.value
    }
    const toPath = (url: string) => {
      router.push(url);
      isShowMobileNav.value = false;
    }
    return {
      level,
      nameRouter,
      isShowMobileNav,
      isAuth,
      toPath,
      navMobile,
      logout: authStore.logout,
    };
  },
  components: {
    Alert,
  },
});
