
  import { watch, defineComponent, ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router'
  import { Preloader } from '../../../components/Preloader';
  import { Error } from '../../../components/Error';
  import { ordersStore } from '../../../store/orders/ordersStore';
  import { tokenStore } from '../../../store/auth/tokenStore';

  export default defineComponent({
    components: { Preloader, Error },
    props: {
      step: { 
        type: Number,
        required: true
      },
    },
    setup(props, { emit }) {
      const router = useRouter();
      const route = useRoute();
      const response = ref('');
      const errors = ref([]);
      const name = ref('');
      const surname = ref('');
      const patronymic = ref('');
      const addres = ref('');
      const phone = ref('');
      const zip = ref();
      const loading = ref(false);
      const tokenOrId = route.query.payment_verification || '';
      window.ym(84257527,'reachGoal','entering_address_data_for_delivery')
      watch(phone, (value, prevValue) => {
        phone.value = value.toString().replace(/[^+\d]/, '')
      })
      watch(zip, (value, prevValue) => {
        zip.value = value.toString().replace(/[^+\d]/, '')
      })
      function handleBack() {
        emit('step', props.step - 1);
      }
      function createCard() {
        const tokenAuth = sessionStorage.getItem('token')
        tokenStore.set(tokenAuth);
        router.push('/')
      }
      function handleNext() {
        errors.value = [];
        loading.value = true; 
        const information = {
          name: name.value,
          surname: surname.value,
          patronymic: patronymic.value,
          adress: addres.value,
          phone: phone.value,
          zip: zip.value
        }
        ordersStore.ordersAdd(tokenOrId.toString(), information).then(r => {
          response.value = r.data.message;
        }).catch(e => {
          const err = e.response.data;
          Object.keys(err).forEach((key: string) => {
            for(let i=0;i<err[key].length;i++) {
              (errors.value as string[]).push(err[key][i]);
            }
          })
        }).finally(() => {
          loading.value = false; 
        })
      }
      return {
        createCard,
        router,
        response,
        name,
        surname,
        patronymic,
        phone,
        zip,
        addres,
        ordersStore,
        errors,
        loading,
        handleNext,
        handleBack,
      };
    },
  })
  