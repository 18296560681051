import router from '@/router';
import { tagService } from '@/services/tags/tagService';
import { reactive, computed } from 'vue';

export interface ITag {
  activated_at: string;
  alias: string;
  card: any;
  created_at: string;
  distribution: string;
  id: number;
  link: string;
  protection_password: string;
  serial_number: string;
  status: string;
  user: any;
  views: number;
}
export interface IForm {
  alias: string;
  pin: string;
  loading: boolean;
}
const form = reactive<IForm>({
  alias: '',
  pin: '',
  loading: false,
});

export interface IState {
  form: IForm;
  loading: boolean;
  list: any[];
  tag: ITag|null;
  tag_user:  any;
  meta: any;
  page: number;
  filter: string|undefined;
  value: string|undefined;
  isShowTagBindingPopup: boolean;
}

const state = reactive<IState>({
  form,
  loading: false,
  list: [],
  tag: null,
  tag_user: null,
  meta: {},
  page: 1,
  filter: undefined,
  value: undefined,
  isShowTagBindingPopup: true,
});

const actions = {
  async search(filter: string, value: string) {
    try {
      state.filter = filter;
      state.value = value;
      state.page = 1;
      this.get();
    }
    catch (e) {
      console.log(e);
    }
  },
  async get(id?: number) {
    try {
      if (id) {
        state.tag = null;
        state.loading = true;
        const { data } = await tagService.get(1, id);
        state.tag = data.data;
      } else {
        state.loading = true;
        const { data } = await tagService.get(state.page, undefined, state.filter, state.value);
        state.list = data.data;
        state.meta = data.meta;
      }
    } catch (error) {
      console.warn('Ошибка загрузки тегов', error);
    } finally {
      state.loading = false;
    }
  },
  async update(tagId: number, data: any) {
    try {
      state.loading = true;
      const response = await tagService.update(tagId, data);
      state.tag = response.data.data;
      this.get();
    } finally {
      state.loading = false;
    }
  },
  async connect() {
    try {
      form.loading = true;
      await tagService.connect(form.alias, form.pin);
      this.resetForm();
      this.get();
      router.push('/tags');
    } catch (error) {
      console.warn('Не удалось привязать тег к аккаунут', error);
    } finally {
      form.loading = false;
    }
  },
  async byUser(id: number) {
    try {
      form.loading = true;
      const r = await tagService.byUser(id);
      return (state.tag_user = r.data.data[0]);
    } catch (error) {
      console.warn('Не удалось получить данные пользователя', error);
    } finally {
      form.loading = false;
    }
  },
  // есть ли свободные тэги
  async freeTags() {
    const response = await tagService.freeTags();
    return !!response.data;
  },
  setForm(alias: string) {
    form.alias = alias;
  },
  resetForm() {
    form.pin = '';
    form.alias = '';
  },
};

export const tagStore = {
  state,
  ...actions,
};
