
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    image: {
      type: String,
      default: ''
    }
  },
  setup(_, { emit }) {
    const close = () => {
      emit('close')
    }
    return {
      close
    };
  },
});
