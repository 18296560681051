
import { defineComponent, computed, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import { tokenStore } from '../../../store/auth/tokenStore';
import { ordersStore } from '../../../store/orders/ordersStore';
import { Preloader } from '../../../components/Preloader';
import { Error } from '../../../components/Error';
import { stockBalanceStore } from '../../../store/stockBalance/stockBalanceStore';
import PictureList from '@/components/PictureList'

export default defineComponent({
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  components: { Preloader, Error, PictureList },
  setup(props, { emit }) {
    const route = useRoute();
    const errors = ref([]);
    const loading = ref(false);
    const stockBalanceList = ref();
    const isClassicModel = ref(false);
    const pictures = [
      './img/model/simple/1.webp',
      './img/model/simple/2.webp'
    ]

    stockBalanceStore.stockBalance().then((r) => {
      stockBalanceList.value = r;
      if (stockBalanceList.value['classic_black'][1]) {
        return isClassicModel.value = true
      }
      if (stockBalanceList.value['classic_white'][1]) {
        return isClassicModel.value = true
      }
      if (stockBalanceList.value['classic_custom'][30]) {
        return isClassicModel.value = true
      }
    });

    const model = computed({
      get(): string {
        return ordersStore.state.utagModel;
      },
      set(value: string) {
        ordersStore.updateUtagModel(value);
      }
    });

    function handleNext() {
      emit('step', props.step + 1);
    }
    return {
      isClassicModel,
      pictures,
      ordersStore,
      errors,
      loading,
      model,
      handleNext,
    };
  },
});
