
import {  computed, defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { activateStore } from '../../store/activate/activateStore';
import { tokenStore } from '../../store/auth/tokenStore'

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const alias = typeof route.params.alias === 'string' ? route.params.alias : ''
    sessionStorage.setItem('alias', alias)
    localStorage.setItem('scan-tag', alias)
    if (!tokenStore.getters.isAuth.value) {
      if (route.query.registration === 'true') {
        router.push(`/auth?alias=${alias}&registration=true`)
      } else {
        router.push(`/auth?alias=${alias}`)
      }
    }
    const isError = ref(false)
    const isSuccess = ref(false)
    const activation_pin = ref('')
    const handleActivate = () => {
      isError.value = false
      isSuccess.value = false
      activateStore.activate(activation_pin.value, alias).then(() => {
        isSuccess.value = true
        router.push("/?activate=success")
      }).catch(() => {
        isError.value = true
      })
      }
    return {
      activation_pin,
      handleActivate,
      isError,
      isSuccess
    };
  },
});
