
  import { defineComponent, ref, computed } from 'vue';
  import { useRoute, useRouter } from "vue-router";
  import { Preloader } from '../../../components/Preloader';
  import { Error } from '../../../components/Error';
  import { payStore } from "../../../store/pay/payStore";
  
  export interface IProduct {
    name: string;
    price: number;
    quantity: number;
    coupon?: string;
  }

  export default defineComponent({
    components: { Error, Preloader },
    props: {
      step: { 
        type: Number,
        required: true
      },
    },
    setup(props, { emit }) {
      const router = useRouter();
      const route = useRoute();
      const errors = ref<string[]>([]);
      const token_or_id = route.query.payment_verification;
      const timer_id = ref(0)
      const check_pay_count = ref(0);
      const is_not_paid = ref(false)
      const loading = ref(false)
      
      const design     = localStorage.getItem("design") || ''
      const price      = parseInt(localStorage.getItem("price-utag") || '1499')
      const quanity    = parseInt(localStorage.getItem("quanity") || '1')
      const coupon     = localStorage.getItem("coupon") || '' 
      const payment_id = localStorage.getItem("payment_id") || ''

      const checkPay = () => {
        errors.value = [];
        loading.value = true;
       
        const products: IProduct[] = []
        if (coupon !== '') {
          products.push({
            name: design,
            price: quanity === 1 ? price : price / 3,
            quantity: quanity,
            coupon: coupon
          })
        } else {
          products.push({
            name: design,
            price: quanity === 1 ? price : price / 3,
            quantity: quanity,
          })
        }
        if (typeof token_or_id === "string") {
            payStore.get(token_or_id).then(() => {
              if (payStore.state.bill_status) {
                loading.value = false;
                window.ym(84257527,'reachGoal','successful_payment')
                clearTimeout(timer_id.value)
                window.dataLayer.push({
                  ecommerce: {
                    currencyCode: "RUB",
                    purchase: {
                      actionField: {
                        id : payment_id
                      },
                      products: products
                    }
                  }
                });
                emit('step', props.step + 1);
              }
            });
        }

        if (check_pay_count.value > 19 || is_not_paid.value) {
          window.ym(84257527,'reachGoal','failed_payment_attempt')
          clearTimeout(timer_id.value)
          setTimeout(() => {
            loading.value = false;
            errors.value.push("Платёж не прошёл")
            is_not_paid.value = true
          }, 3000)
        }

        if (!is_not_paid.value) {
          check_pay_count.value += 1
        }
      }

      if (token_or_id) {
        timer_id.value = setInterval(checkPay, 3000)
      }

      function handleNext() {
        errors.value = [];
        checkPay();
      }
      return {
        loading,
        is_not_paid,
        token_or_id,
        errors,
        handleNext,
      };
    },
  })
  